
import { useContext } from 'react'

import {
  Background,
  Card,
  Divider,
  Flex,
  Formatter,
  Image,
  Link,
  List,
  ListItem,
  Text,
} from 'react-mobi'

import { Default } from '../layouts'
import { LevelsContext, RewardsContext } from '../commons'
import { ContractCell, Subtitle, Title } from '../components'

export default function Rewards() {
  const footer = (
    <Flex>
      <Flex gap='1rem' margin='2rem 0 3rem 0' width='60%'>
        <Image src='/images/calendar_icon.png' height='15rem' />
        <Text bold center line={1} size='3rem' tertiary>
          When can I expect my new discount to be applied?
        </Text>
        <Text center tertiary>
          When you reach a milestone, you can expect to see your savings as soon as your next statement.
        </Text>
      </Flex>
    </Flex>
  )
  const heading = (
    <>
      <Title>
        Welcome to Mobiloans Rewards
      </Title>
      <Subtitle>
        Once you make your first cash withdrawal, you will be enrolled in the Mobiloans Rewards Program. By paying on time, you can start earning points that will reduce Cash Advance Fees and Fixed Finance Charges<Text super tertiary>1</Text> for future cash advances with Mobiloans.<br />
        <br />
        Collecting these points is easy!
        <Flex style={{position: 'relative'}}>
          <Image
            height='9rem'
            src="/images/award.png"
            style={{bottom: '-4rem', position: 'absolute', right: '-8rem', zIndex: 4}}
            width='10rem' />
        </Flex>
      </Subtitle>
    </>
  )
  const levels = useContext(LevelsContext)
  const rewards = useContext(RewardsContext)

  const levelsComponents = []
  for (let i = 0; i < levels.length; i++) {
    const level = levels[i]
    levelsComponents.push(
      <Flex between full gap='1rem' key={level.name} padding='0 0 2rem 0'>
        <Flex>
          <Image src={`/images/${level.name}_icon.png`} />
          <Text padding={0} margin='-1rem 0 0 0'>Accrue</Text>
        </Flex>
        <Flex>
          <Text bold line={1} size='1.5rem'>{Formatter.number(level.points)}</Text>
          <Text bold line={1} padding={0} size='0.85rem'>points</Text>
        </Flex>
        <Flex>
          <Text padding={0} size='0.75rem'>And enjoy</Text>
          <Text bold line={1} primary padding={0} size='3rem'>{Formatter.percentage(level.discount, 0)}</Text>
          <Text bold line={1} primary padding={0} size='1.75rem'>off</Text>
        </Flex>
      </Flex>
    )
    if (i < levels.length - 1) {
      levelsComponents.push(
        <Divider color='#efefef' dots={false} height='90%' key={i} vertical />
      )
    }
  }

  return (
    <Default footer={footer} heading={heading}>
      <Flex full gap='5rem' padding='5rem 0'>
        <Text bold center size='3rem'>
          How do I earn points?
        </Text>
        <Flex>
          <Flex autoWrap between full gap='1rem' padding='2rem' row stretch>
            {rewards.map((reward, index) => (
              <Flex full key={index}>
                <Card padding='1rem'>
                  <Flex>
                    <Flex height='10rem'>
                      <Text center>
                        Earn<br />
                        <Text bold primary size='3rem'>+{Formatter.number(reward.points)}</Text><br />
                        points
                      </Text>
                    </Flex>
                    <Text bold center height='5rem' size='1.5rem'>
                      {reward.action}
                    </Text>
                    <Text center height='10rem'>
                      {reward.description}
                    </Text>
                  </Flex>
                </Card>
              </Flex>
            ))}
          </Flex>
          <Flex margin='1rem 2rem'>
            <Text>
              *Points will be awarded to a customer for (a) making a timely and successful payment on or before due date for the minimum payment due and (b) paying in full the outstanding balance of your Mobiloans Credit Account. A customer's payment is not "successful" if any portion of a payment is returned or otherwise cannot be processed or if the payment is not for the entire amount due. Maximum number of points that can be earned per month is 2,000.
            </Text>
            <Text>
              **The Reward Discount shown in the chart below will reduce the standard Cash Advance Fees<Text super>1</Text> and Fixed Finance Charges<Text super>1</Text> by the percentage of the Reward Discount.
            </Text>
          </Flex>
        </Flex>
        <Divider width='75%' />
        <Text bold center size='3rem'>
          Earn points and save!
        </Text>
        <Card margin='0 2rem'>
          <Flex>
            <Flex autoWrap evenly full row>
              {levelsComponents}
            </Flex>
            <Flex start style={{margin: '0 2rem 1rem 2rem'}}>
              <Text>
                <Text bold padding={0} primary>Don't worry</Text> - once you have earned points or achieved a Reward Level, we won't deduct points or reduce your discount.  But, if you have problems paying or miss a payment, we will pause your ability to earn points until you can reestablish a good payment history.
              </Text>
            </Flex>
          </Flex>
        </Card>
        <Flex full stretch>
          <Background tertiary>
            <Flex autoWrap evenly full row padding='2rem'>
              <Text size='2rem'>
                Examples
              </Text>
              <Divider height='5rem' vertical />
              <List ordered>
                <ListItem>
                  <Text size='1.25rem'>
                    With just 20 on-time payments, you upgrade to Silver and enjoy 10% off.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text size='1.25rem'>
                    With just 30 pay-in-full payments, you upgrade to Gold and enjoy 20% off.
                  </Text>
                </ListItem>
              </List>
            </Flex>
          </Background>
        </Flex>
        <Text bold center size='3rem'>
          See what you can save with rewards!
        </Text>
        <Flex center gap='1rem' margin='0 2rem'>
          <Text center size='1.25rem' width='75%'>
            If you draw $1,000 and pay it off over <ContractCell type='RewardsBillingCycles' /> billing cycles, making only the minimum payment due and without drawing any more funds, your repayment schedule and savings would be as follows:
          </Text>
          <Text center primary size='1.25rem' width='75%'>
            ex. Gold Rewards Savings for a $1,000 Draw
          </Text>
          <Card>
            <table className='gold'>
              <thead>
                <tr>
                  <th></th>
                  <th>Before Rewards</th>
                  <th>Gold 20% off<Text super>*</Text></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Amount drawn</td>
                  <td>$1,000</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Principal repayment</td>
                  <td>$1,000</td>
                  <td>$1,000</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Payment of cash advance fee<Text super>1</Text></td>
                  <td>$137.50</td>
                  <td>$110</td>
                  <td className='tertiary'>Save 20%</td>
                </tr>
                <tr>
                  <td>Payment of fixed finance charges<Text super>1</Text></td>
                  <td>$1,910</td>
                  <td>$1,528</td>
                  <td className='tertiary'>Save 20%</td>
                </tr>
                <tr>
                  <td>Total paid:</td>
                  <td>$3,047.50</td>
                  <td>$2,638</td>
                  <td className='tertiary'>$409.50</td>
                </tr>
              </tbody>
            </table>
          </Card>
          <Text>
            *Discount applies to all Cash Advance Fees and Fixed Finance Charges.<Text super>1</Text>
          </Text>
        </Flex>
        <Flex margin='2rem'>
          <Link button href='/terms-and-conditions'>View Rewards Terms and Conditions</Link>
        </Flex>
      </Flex>
    </Default>
  )
}
