
import { createContext } from 'react'
import { ProviderType } from './interfaces'

export const DataContext = createContext({
  'Fee_V1': {
    apr: {
      breakdowns: [
        {amount: 200, apr: 4.4231, cycles: 13, fees: 230, rewards: 1.5481},
        {amount: 1000, apr: 3.2, cycles: 25, fees: 1600, rewards: 1.12},
        {amount: 1300, apr: 2.9528, cycles: 38, fees: 2879, rewards: 1.0335},
        {amount: 1500, apr: 2.7258, cycles: 50, fees: 4010, rewards: .9540},
        {amount: 2500, apr: 2.0614, cycles: 60, fees: 6045, rewards: .7215},
      ],
      formula: '$$APR=(\\frac{(\\frac{\\text{Total Fees}}{\\text{Avg. Principal Balance}})}{\\text{Number Of Billing Cycles}})*\\text{Billing Cycles Per Year}*100$$',
      max: 4.4231,
      min: .7215,
    },
    amounts: [
      {max: 400, payment: 16},
      {max: 1099.99, min: 400.01, percentage: .040000},
      {max: 1399.99, min: 1100, percentage: .026316},
      {max: 1999.99, min: 1400, percentage: .020000},
      {max: 2500, min: 2000, percentage: .016667},
    ],
    charges: [
      {amount: 0, max: 10, min: 0},
      {amount: 10, max: 100, min: 10.01},
      {amount: 25, max: 200, min: 100.01},
      {amount: 35, max: 300, min: 200.01},
      {amount: 45, max: 400, min: 300.01},
      {amount: 95, max: 600, min: 400.01},
      {amount: 105, max: 900, min: 600.01},
      {amount: 110, max: 1000, min: 900.01},
      {amount: 125, max: 2000, min: 1000.01},
      {amount: 135, max: 2500, min: 2000.01},
    ],
    fees: [
      {amount: 20, max: 500, rate: 3.0},
      {amount: 20, max: 1000, min: 500, rate: 2.0},
      {amount: 20, max: 1400, min: 1000, rate: 1.6},
      {amount: 20, max: 2000, min: 1400, rate: 1.4},
      {amount: 20, max: 2500, min: 2000, rate: 1.0},
    ],
    text: {
      'LineLowText' :          'two hundred dollars ($200)',
      'LineHighText' :         'two thousand five hundred dollars ($2,500)',
      'LineLowAmt' :           '$200',
      'LineHighAmt' :          '$2,500',
      'Title' :                'Mobiloans Line of Credit Account Terms and Conditions Effective January 22, 2022',
      '12Mos' :                'twelve months',
      '3Times' :               'three',
      '4PM' :                  '4 pm',
      'One' :                  'one',
      'Residency' :            'reside in a state in which Mobiloans provides its services',
      'GracePeriod' :          '',
      'Comma' :                ',',
      'RewardsBillingCycles':  '25',
      'PrincipalBalances' :    'Principal balances under one thousand one hundred dollars ($1,100.00) have a maximum bi-weekly term of up to twenty-five (25) Billing Cycles; principal balances between one thousand one hundred dollars ($1,100.00) and one thousand three hundred ninety-nine dollars and ninety-nine cents ($1,399.99) have a maximum bi-weekly term of thirty-eight (38) Billing Cycles; principal balances between one thousand four hundred dollars ($1,400.00) and one thousand nine hundred ninety-nine dollars and ninety-nine cents ($1,999.99) have a maximum bi-weekly term of fifty (50) Billing Cycles; and principal balances between two thousand dollars ($2,000.00) and two thousand five hundred dollars ($2,500.00) have a maximum bi-weekly term of sixty (60) Billing Cycles.',
      'Arbitration' :          '<span class="bold italic">Choice of Arbitrator.</span> Any party to a Dispute, including a Holder, may send the other party written notice by certified mail return receipt requested at the address appearing at the top of the Agreement of their intent to arbitrate and setting forth the subject of the dispute along with the relief requested, even if a lawsuit has been filed. Regardless of who demands arbitration, you shall have the right to select any of the following arbitration organizations to administer the arbitration: the American Arbitration Association (1-800-778-7879) <a href="https://www.adr.org" target="_blank">https://www.adr.org</a>; JAMS (1-800-352-5267) <a href="https://www.jamsadr.com" target="_blank">https://www.jamsadr.com</a>; or an arbitration organization agreed upon by you and the other parties to the Dispute.  The chosen arbitrator will utilize the rules and procedures applicable to consumer disputes of the chosen arbitration organization, but only to the extent that those rules and procedures are consistent with the terms of this Agreement, Tribal Law and applicable federal law.  The party receiving notice of Arbitration will respond in writing by certified mail return receipt requested within twenty (20) days.  You understand that if you demand Arbitration, you must inform us of your demand and of the arbitration organization you have selected.  You also understand that if you fail to notify us, then we have the right to select the arbitration organization in accordance with Tribal Law or applicable federal law.  Any arbitration under this Agreement may be conducted either on Tribal land or within thirty miles of your residence, at your choice, provided that this accommodation for you shall not be construed in any way (a) as a relinquishment or waiver of the Tribe’s sovereign status or immunity, or (b) to allow for the application of any law other than Tribal Law or applicable federal law.',
      'ArbAgreement' :         '<span class="bold italic">Agreement to Arbitrate.</span>  You agree that any Dispute (defined below) will be resolved by arbitration in accordance with Tribal Law and applicable federal law.',
      'ArbDefined' :           '<span class="bold italic">Arbitration Defined.</span>  Arbitration is a means of having an independent third party resolve a Dispute. A <span class="italic">“Dispute”</span> is any controversy or claim related in any way to your Mobiloans Credit Account or your application for a Mobiloans Credit Account, involving you and Lender, its marketing agent, collection agent, any subsequent holder of your Mobiloans Credit Account, or any of their respective agents, affiliates, assigns, employees, officers, managers, members or shareholders (each considered a <span class="italic">“Holder”</span> for purposes of this Agreement). The term Dispute is to be given its broadest possible meaning and includes, without limitation, all claims or demands (whether past, present, or future, including events that occurred prior to the opening of your Account), based on any legal or equitable theory (tort, contract, or otherwise), and regardless of the type of relief sought (i.e., money, injunctive relief, or declaratory relief). A Dispute includes, by way of example and without limitation, any claim arising from, related to or based upon marketing or solicitations to obtain the Mobiloans Credit Account and the handling or servicing of your Account whether such Dispute is based on a Tribal, federal or state constitution, statute, ordinance, regulation, or common law, and including any issue concerning the validity, enforceability, or scope of this Account or the Arbitration Agreement.',
      'ArbClass' :             '',
      'ArbInitiation' :        '',
      'ArbCost' :              '<span class="bold italic">Cost of Arbitration.</span> We will pay the filing fee and any costs or fees charged by the arbitrator regardless of which party initiates the arbitration. Except where otherwise provided by Tribal Law or applicable federal law, each party will be responsible for its own attorneys’ fees and other expenses.  Unless prohibited by Tribal Law or applicable federal law, the arbitrator may award fees, costs, and reasonable attorneys’ fees to the party who substantially prevails in the arbitration.',
      'ArbReview' :            '<span class="bold italic">Judicial Review.</span> The arbitrator will apply the terms of this Agreement, including the Arbitration Agreement, Tribal Law, and federal law as appropriate. The arbitrator may decide, with or without a hearing, any motion that is substantially similar to a motion to dismiss for failure to state a claim or a motion for summary judgment.  If allowed by Tribal or federal statute or applicable law, the arbitrator may award statutory damages and/or reasonable attorneys’ fees and expenses.  The arbitrator will make written findings and the arbitrator’s award may be filed with the Tribal court.  The arbitration award will be supported by substantial evidence and must be consistent with this Agreement and applicable law or may be set aside by the Tribal court upon judicial review.',
      'CashAdvFeeExample' :    'For example, a $200 Cash Advance on a biweekly customer = ((230/104)/13)*(26*100) = 442.31%',
    },
  },
  'Fee_V2': {
    apr: {
      breakdowns: [
        {amount: 500, apr: 4, cycles: 25, fees: 1000, rewards: 1.40},
        {amount: 1000, apr: 3.5862, cycles: 28, fees: 2000, rewards: 1.2552},
        {amount: 2500, apr: 2.2607, cycles: 60, fees: 6630, rewards: .7913},
      ],
      formula: '$$APR=(\\frac{(\\frac{\\text{Total Fees}}{\\text{Avg. Principal Balance}})}{\\text{Number Of Billing Cycles}})*\\text{Billing Cycles Per Year}*100$$',
      max: 4.0,
      min: .7913,
    },
    amounts: [
      {max: 400, payment: 16},
      {max: 899.99, min: 400.01, percentage: .040000},
      {max: 999.99, min: 900, percentage: .038467},
      {max: 1099.99, min: 1000, percentage: .035710},
      {max: 1199.99, min: 1100, percentage: .026316},
      {max: 1299.99, min: 1200, percentage: .025000},
      {max: 1399.99, min: 1300, percentage: .023808},
      {max: 1499.99, min: 1400, percentage: .022222},
      {max: 1999.99, min: 1500, percentage: .020000},
      {max: 2500, min: 2000, percentage: .016667},
    ],
    charges: [
      {amount: 0, max: 10, min: 0},
      {amount: 15, max: 100, min: 10.01},
      {amount: 30, max: 200, min: 100.01},
      {amount: 35, max: 300, min: 200.01},
      {amount: 45, max: 400, min: 300.01},
      {amount: 75, max: 500, min: 400.01},
      {amount: 85, max: 600, min: 500.01},
      {amount: 95, max: 700, min: 600.01},
      {amount: 105, max: 900, min: 700.01},
      {amount: 115, max: 1000, min: 900.01},
      {amount: 125, max: 1400, min: 1000.01},
      {amount: 140, max: 2500, min: 1400.01},
    ],
    fees: [
      {amount: 20, max: 500, rate: 3.0},
      {amount: 20, max: 1000, min: 500, rate: 2.0},
      {amount: 20, max: 1400, min: 1000, rate: 1.6},
      {amount: 20, max: 2000, min: 1400, rate: 1.4},
      {amount: 20, max: 2500, min: 2000, rate: 1.0},
    ],
    text: {
      'LineLowText':          'five hundred dollars ($500)',
      'LineHighText':         'two thousand five hundred dollars ($2,500)',
      'LineLowAmt':           '$500',
      'LineHighAmt':          '$2,500',
      'Title':                'Mobiloans Line of Credit Account Terms and Conditions Effective May 17, 2022',
      '12Mos':                'twelve (12) months',
      '3Times':               'three (3)',
      '4PM':                  '4:00 pm',
      'One':                  'one (1)',
      'Residency':            'reside in a state in which Mobiloans provides its services',
      'GracePeriod':          '',
      'Comma':                ',',
      'RewardsBillingCycles': '28',
      'PrincipalBalances':    'Principal balances up to eight hundred dollars ($800.00) have a maximum bi-weekly term of up to twenty-five (25) Billing Cycles; principal balances between eight hundred and one dollars ($801.00) and one thousand one hundred dollars ($1,100.00) have a maximum bi-weekly term of thirty-eight (38) Billing Cycles; principal balances between one thousand one hundred and one dollars ($1,101.00) and one thousand nine hundred ninety-nine dollars and ninety-nine cents ($1,999.99) have a maximum bi-weekly term of fifty (50) Billing Cycles; and principal balances between two thousand dollars ($2,000.00) and two thousand five hundred dollars ($2,500.00) have a maximum bi-weekly term of sixty (60) Billing Cycles.',
      'Arbitration':          '<span class="bold italic">Choice of Arbitrator.</span> Any party to a Dispute, including a Holder, may send the other party written notice by certified mail return receipt requested at the address appearing at the top of the Agreement of their intent to arbitrate and setting forth the subject of the dispute along with the relief requested, even if a lawsuit has been filed. Regardless of who demands arbitration, you shall have the right to select any of the following arbitration organizations to administer the arbitration: the American Arbitration Association <a href="https://www.adr.org" target="_blank">https://www.adr.org</a>; JAMS (1-800-352-5267) <a href="https://www.jamsadr.com" target="_blank">https://www.jamsadr.com</a>; or an arbitration organization agreed upon by you and the other parties to the Dispute.  The chosen arbitrator will utilize the rules and procedures applicable to consumer disputes of the chosen arbitration organization, but only to the extent that those rules and procedures are consistent with the terms of this Agreement, Tribal Law and applicable federal law.  The party receiving notice of Arbitration will respond in writing by certified mail return receipt requested within twenty (20) days.  You understand that if you demand Arbitration, you must inform us of your demand and of the arbitration organization you have selected.  You also understand that if you fail to notify us, then we have the right to select the arbitration organization in accordance with Tribal Law or applicable federal law.  Any arbitration under this Agreement may be conducted either on Tribal land or within thirty miles of your residence, at your choice, provided that this accommodation for you shall not be construed in any way (a) as a relinquishment or waiver of the Tribe’s sovereign status or immunity, or (b) to allow for the application of any law other than Tribal Law or applicable federal law.',
      'ArbAgreement':         '<span class="bold italic">Agreement to Arbitrate.</span>  You agree that any Dispute (defined below) will be resolved by arbitration in accordance with Tribal Law and applicable federal law.',
      'ArbDefined':           '<span class="bold italic">Arbitration Defined.</span>  Arbitration is a means of having an independent third party resolve a Dispute. A <span class="italic">“Dispute”</span> is any controversy or claim related in any way to your Mobiloans Credit Account or your application for a Mobiloans Credit Account, involving you and Lender, its marketing agent, collection agent, any subsequent holder of your Mobiloans Credit Account, or any of their respective agents, affiliates, assigns, employees, officers, managers, members or shareholders (each considered a <span class="italic">“Holder”</span> for purposes of this Agreement). The term Dispute is to be given its broadest possible meaning and includes, without limitation, all claims or demands (whether past, present, or future, including events that occurred prior to the opening of your Account), based on any legal or equitable theory (tort, contract, or otherwise), and regardless of the type of relief sought (i.e., money, injunctive relief, or declaratory relief). A Dispute includes, by way of example and without limitation, any claim arising from, related to or based upon marketing or solicitations to obtain the Mobiloans Credit Account and the handling or servicing of your Account whether such Dispute is based on a Tribal, federal or state constitution, statute, ordinance, regulation, or common law, and including any issue concerning the validity, enforceability, or scope of this Account or the Arbitration Agreement.',
      'ArbClass':             '',
      'ArbInitiation':        '',
      'ArbCost':              '<span class="bold italic">Cost of Arbitration.</span> We will pay the filing fee and any costs or fees charged by the arbitrator regardless of which party initiates the arbitration. Except where otherwise provided by Tribal Law or applicable federal law, each party will be responsible for its own attorneys’ fees and other expenses.  Unless prohibited by Tribal Law or applicable federal law, the arbitrator may award fees, costs, and reasonable attorneys’ fees to the party who substantially prevails in the arbitration.',
      'ArbReview':            '<span class="bold italic">Judicial Review.</span> The arbitrator will apply the terms of this Agreement, including the Arbitration Agreement, Tribal Law, and federal law as appropriate. The arbitrator may decide, with or without a hearing, any motion that is substantially similar to a motion to dismiss for failure to state a claim or a motion for summary judgment.  If allowed by Tribal or federal statute or applicable law, the arbitrator may award statutory damages and/or reasonable attorneys’ fees and expenses.  The arbitrator will make written findings and the arbitrator’s award may be filed with the Tribal court.  The arbitration award will be supported by substantial evidence and must be consistent with this Agreement and applicable law or may be set aside by the Tribal court upon judicial review.',
      'CashAdvFeeExample':    'For example, a $500 Cash Advance on a biweekly customer = ((1000/260)/25)*(26*100) = 400.00%',
    },
  },
  'Fee_V3': {
    apr: {
      breakdowns: [
        {amount: 500, apr: 4.05, cycles: 25, fees: 1012.50, rewards: 1.4175},
        {amount: 1000, apr: 3.6712, cycles: 28, fees: 2047, rewards: 1.2849},
        {amount: 2500, apr: 2.3886, cycles: 60, fees: 7005.05, rewards: .8360},
      ],
      formula: '$$APR=(\\frac{(\\frac{\\text{Total Fees}}{\\text{Avg. Principal Balance}})}{\\text{Number Of Billing Cycles}})*\\text{Billing Cycles Per Year}*100$$',
      max: 4.05,
      min: .836,
    },
    amounts: [
      {max: 400, payment: 16},
      {max: 899.99, min: 400.01, percentage: .040000},
      {max: 999.99, min: 900, percentage: .038467},
      {max: 1099.99, min: 1000, percentage: .035710},
      {max: 1199.99, min: 1100, percentage: .026316},
      {max: 1299.99, min: 1200, percentage: .025000},
      {max: 1399.99, min: 1300, percentage: .023808},
      {max: 1499.99, min: 1400, percentage: .022222},
      {max: 1999.99, min: 1500, percentage: .020000},
      {max: 2500, min: 2000, percentage: .016667},
    ],
    charges: [
      {amount: 0, max: 10, min: 0},
      {amount: 15, max: 100, min: 10.01},
      {amount: 30, max: 200, min: 100.01},
      {amount: 35, max: 300, min: 200.01},
      {amount: 45, max: 400, min: 300.01},
      {amount: 75, max: 500, min: 400.01},
      {amount: 85, max: 600, min: 500.01},
      {amount: 95, max: 700, min: 600.01},
      {amount: 105, max: 900, min: 700.01},
      {amount: 115, max: 1000, min: 900.01},
      {amount: 135, max: 1400, min: 1000.01},
      {amount: 150, max: 2500, min: 1400.01},
    ],
    fees: [
      {amount: 20, max: 500, rate: 3.5},
      {amount: 20, max: 1000, min: 500, rate: 2.75},
      {amount: 20, max: 1200, min: 1000, rate: 2.25},
      {amount: 20, max: 1500, min: 1200, rate: 2},
      {amount: 20, max: 1800, min: 1500, rate: 1.65},
      {amount: 20, max: 2000, min: 1800, rate: 1.5},
      {amount: 20, min: 2000, rate: 1.2},
    ],
    text: {
      'LineLowText':          'five hundred dollars ($500)',
      'LineHighText':         'two thousand five hundred dollars ($2,500)',
      'LineLowAmt':           '$500',
      'LineHighAmt':          '$2,500',
      'Title':                'Mobiloans Line of Credit Account Terms and Conditions Effective June 7, 2024',
      '12Mos':                'twelve (12) months',
      '3Times':               'three (3)',
      '4PM':                  '4:00 pm',
      'One':                  'one (1)',
      'Residency':            'meet certain residency requirements',
      'GracePeriod':          'There is no grace period.',
      'Comma':                '',
      'RewardsBillingCycles': '28',
      'PrincipalBalances':    'Principal balances up to eight hundred ninety-nine dollars and ninety-nine cents ($899.99) have a maximum bi-weekly (two (2) payments per month) term of up to twenty-five (25) Billing Cycles; principal balances between nine hundred dollars ($900.00) and nine hundred ninety-nine dollars and ninety-nine cents ($999.99) have a maximum bi-weekly term of twenty-six (26) Billing Cycles; principal balances between one thousand dollars ($1,000.00) and one thousand ninety-nine dollars and ninety-nine cents ($1,099.99) have a maximum bi-weekly term of twenty-eight (28) Billing Cycles; principal balances between one thousand one hundred dollars ($1,100.00) and one thousand one hundred and ninety-nine dollars and ninety-nine cents ($1,199.99) have a maximum bi-weekly term of thirty-eight (38) Billing Cycles; principal balances between one thousand two hundred dollars ($1,200.00) and one thousand two hundred ninety-nine dollars and ninety-nine cents ($1,299.99) have a maximum bi-weekly term of forty (40) Billing Cycles; principal balances between one thousand three hundred dollars ($1,300.00) and one thousand three hundred ninety-nine dollars and ninety-nine cents ($1,399.99) have a maximum bi-weekly term of forty-two (42) Billing Cycles; principal balances between one thousand four hundred dollars ($1,400.00) and one thousand four hundred ninety-nine dollars and ninety-nine cents ($1,499.99) have a maximum of forty-five (45) Billing Cycles; principal balances between one thousand five hundred dollars ($1,500.00) and one thousand nine hundred ninety-nine dollars and ninety-nine cents (1,999.99) have a maximum of fifty (50) Billing Cycles; and principal balances between two thousand dollars ($2,000.00) and two thousand five hundred dollars ($2,500.00) have a maximum bi-weekly term of sixty (60) Billing Cycles.',
      'Arbitration':          '<span class="bold italic">Arbitration Procedural Rules.</span>  The arbitration will proceed according to the rules and procedures used by the applicable arbitration organization for consumer disputes, to the extent those rules and procedures do not contradict the express terms of this Agreement, Tribal law and applicable federal law. You may obtain a copy of the rules and procedures by contacting the selected arbitration organization.     Any arbitration under this Agreement may be conducted on Tribal land, within thirty miles of your residence, or by telephone or other agreed upon electronic platform, at your choice, provided that this accommodation for you shall not be construed in any way (a) as a relinquishment or waiver of the Tribe’s sovereign status or immunity, or (b) to allow for the application of any law other than Tribal Law or applicable federal law.',
      'ArbAgreement':         '<span class="bold italic">Agreement to Arbitrate.</span>  You agree that any Dispute (defined below) will be resolved by arbitration in accordance with Tribal Law and applicable federal law. By agreeing to this provision, you acknowledge and agree that the laws of the Tribe rather than the laws of your state or any other state will apply.',
      'ArbDefined':           '<span class="bold italic">Arbitration Defined.</span>  Arbitration is a means of having an independent third party resolve a Dispute.  A <span class="italic">“Dispute”</span> is any and all controversies or claims related in any way to your Mobiloans Credit Account or your application for a Mobiloans Credit Account, involving you and Lender, its marketing agent, collection agent, any subsequent holder of your Mobiloans Credit Account, or any of their respective agents, affiliates, assigns, employees, officers, managers, members or shareholders (each considered a <span class="italic">“Holder”</span> for purposes of this Agreement).  The term Dispute is to be given its broadest possible meaning and includes, without limitation, all claims or demands (whether past, present, or future, including events that occurred prior to the opening of your Account), based on any legal or equitable theory, including under federal law (i.e., statutory  contract, or otherwise), and regardless of the type of relief sought (i.e., money damages, injunctive relief, or declaratory relief).  A Dispute includes, by way of example and without limitation, any claim arising from, related to or based upon marketing or solicitations to obtain the Mobiloans Credit Account and the handling or servicing of your Account , and including any issue concerning the validity, enforceability, or scope of this Account or the Arbitration Agreement.',
      'ArbClass':             'ANY ARBITRATION OF ANY DISPUTE UNDER THIS AGREEMENT SHALL BE AN INDIVIDUAL CONSUMER ARBITRATION ONLY; NO CLASS ACTION ARBITRATION AND NO COURT ADJUDICATION THEREOF SHALL BE AVAILABLE.',
      'ArbInitiation':        '<span class="bold italic">Initiation of Arbitration.</span>  Any party to a Dispute, including a Holder, must send the other party written notice by certified mail return receipt requested at the address appearing at the top of the Agreement of their intent to arbitrate and setting forth the claims and relief requested 30-days before filing a demand for Arbitration.  You understand that if you demand Arbitration, the demand for Arbitration must be sent directly to the arbitration organization you have selected.  Regardless of who demands arbitration, you shall have the right to select any of the following arbitration organizations to administer the arbitration: the American Arbitration Association (1-800-778-7879) <a href="https://www.adr.org" target="_blank">https://www.adr.org</a>; JAMS (1-800-352-5267) <a href="https://www.jamsadr.com" target="_blank">https://www.jamsadr.com</a>; or an arbitration organization agreed upon by you and the other parties to the Dispute.  You also understand that if you fail to make a selection, then we have the right to select the arbitration organization in accordance with Tribal Law or applicable federal law.',
      'ArbCost':              '<span class="bold italic">Cost of Arbitration.</span>  We will pay the arbitration filing fee upon receipt of an invoice from the chosen arbitration organization, regardless of which party initiates the arbitration.  Costs and fees of the arbitrator shall be shared equally. Except where otherwise provided by Tribal Law or applicable federal law, each party will be responsible for its own attorneys’ fees and other expenses.  Unless prohibited by Tribal Law or applicable federal law, the arbitrator may award fees, costs, and reasonable attorneys’ fees to the party who substantially prevails in the arbitration.',
      'ArbReview':            '<span class="bold italic">Judicial Review.</span>  The arbitrator will apply the terms of this Agreement, including the Arbitration Agreement, Tribal Law, and federal law as applicable.  The arbitrator may decide, with or without a hearing, any motion that is substantially similar to a motion to dismiss for failure to state a claim or a motion for summary judgment.  If allowed by Tribal or applicable federal law, the arbitrator may award statutory damages and/or reasonable attorneys’ fees and expenses.  The arbitrator will make written findings and the arbitrator’s award may be filed with the Tribal court.  The arbitration award will be supported by substantial evidence and must be consistent with this Agreement and applicable law or may be set aside by the Tribal court upon judicial review.',
      'CashAdvFeeExample':    'For example, a $500 Cash Advance on a biweekly customer = ((1012.50/260)/25)*(26*100) = 405.00%',
    },
  },
})

export const LevelsContext = createContext([
  {discount: 0.1, name: 'silver', points: 10000},
  {discount: 0.2, name: 'gold', points: 30000},
  {discount: 0.35, name: 'platinum', points: 50000},
  {discount: 0.65, name: 'diamond', points: 80000},
])

export const RewardsContext = createContext([
  {
    action: 'Program Enrollment',
    description: 'Earn points simply by participating in Mobiloans Rewards.',
    points: 250,
  },
  {
    action: 'Additional Payments',
    description: 'When the minimum payment due has been made, any additional payment of at least $50 towards your outstanding balance will earn you an additional 250 points.',
    points: 250,
  },
  {
    action: 'Minimum Due Payments',
    description: 'Make a timely payment on a minimum amount due and earn 500 points.',
    points: 500,
  },
  {
    action: 'Pay in Full Payments',
    description: 'Earn points and save money by paying off your line of credit as soon as possible!',
    points: 1000,
  },
])

export const ProviderContext = createContext<ProviderType>({
  amounts: [],
  apr: {
    breakdowns: [],
    formula: '',
    max: 0,
    min: 0,
  },
  charges: [],
  fees: [],
  isLoggedIn: false,
  text: {},
  version: 'Fee_V1',
})

export const StatesContext = createContext([
  'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID',
  'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND',
  'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX',
  'UM', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY',
])

export const VersionContext = createContext('Fee_V3')
