
import { Fetcher } from 'react-mobi'

interface CheckStateResponse {
  ClientAction: Record<string, boolean | null | number | string>
  Errors: Object
  IsValid: boolean
}

interface SiteToastMessage {
  Message: string
  SiteToastMessageType: string
}

interface SiteToastResponse {
  SiteToastMessages: Array<SiteToastMessage>
  SiteToastCount: number
}

export class Service {
  private static fetch: Fetcher = new Fetcher()

  static async checkState(state: string): Promise<boolean> {
    try {
      const data: FormData = new FormData()
      data.append('state', state)

      const response: CheckStateResponse = await this.fetch.post<CheckStateResponse>('/apply/validatenostatefornewcustomer', data)
      if (response.ClientAction['Data'] === 'True') {
        throw new Error(response.ClientAction['Function'] as string)
      }
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  static async siteToast(): Promise<string | undefined> {
    try {
      const response: SiteToastResponse = await this.fetch.get<SiteToastResponse>('/sitetoast')
      if (response.SiteToastCount) {
        const message = response.SiteToastMessages[0].Message
        return message
      }
      return undefined
    } catch (err) {
      console.error(err)
      return undefined
    }
  }
}
