
import { ReactNode } from 'react'

import {
  Card,
  Flex,
} from 'react-mobi'

export interface LandingProps {
  children: ReactNode
  heading: ReactNode
}

export function Landing({children, heading}: LandingProps) {
  return (
    <>
      {heading}
      <Card dark margin='-1.5rem 0 0 0'>
        <Flex full>
          {children}
        </Flex>
      </Card>
    </>
  )
}
