
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
} from 'react-mobi'

import { Default } from '../layouts'
import { Title } from '../components'

export default function Security() {
  const heading = (
    <Title>Security Center</Title>
  )

  return (
    <Default heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Flex gap='1rem' start>
          <Text bold size='1.2rem'>
            Keeping your financial and personal information secure and confidential is one of our most important responsibilities, and we take that responsibility very seriously.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            What we do to protect your personal information
          </Text>
          <Text>
            At Mobiloans, we understand how important security and privacy are to you. Here are just a few of the steps we take to protect your personal information:
          </Text>
          <List>
            <ListItem>
              Computer virus protection helps to detect and prevent computer viruses from entering our computer network systems.
            </ListItem>
            <ListItem>
              Firewalls help to block unauthorized access by individuals or networks.
            </ListItem>
            <ListItem>
              Secure data transmissions help to ensure that information remains confidential. Mobiloans uses 128 bit Secure Socket Layer (SSL) encryption to transmit information to you and your bank.
            </ListItem>
          </List>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            What you can do to protect your personal information
          </Text>
          <Text>
            You play an important role in protecting your information. Here are some tips for making sure that your personal information is protected.
          </Text>
          <List>
            <ListItem>
              Regular email is not secure, so don't use email to send confidential account information. Instead, use the "Contact Us" link on our web site to send secure messages to Mobiloans.<br />
              <Link href='/contact-us'>Contact Us</Link>
            </ListItem>
            <ListItem>
              We recommend that you use a recent version of either Microsoft Internet Explorer or Firefox to access our web site. These browsers support the latest encryption and will help to ensure that your connection to Mobiloans is secure.
            </ListItem>
            <ListItem>
              Choose passwords that are difficult for others to guess. Use a mix of upper and lowercase alphabetical characters and numerical characters when creating a password. Don't use your personal information such as your birth date or pet's name as a password. Keep your passwords for each account unique, and don't write your passwords down in a place where others could find them.
            </ListItem>
            <ListItem>
              Secure your computer by using antivirus and firewall software. Also, make sure that you keep your operating system up to date and install the latest security patches. See the links below for more information on protecting your PC.
            </ListItem>
            <ListItem>
              Remember that Mobiloans will never ask for personal information such as passwords and account numbers through email.
            </ListItem>
            <ListItem>
              Be on the lookout! If you notice anything suspicious, please contact Mobiloans immediately.<br />
              <Link href='/contact-us'>Contact Us</Link>
            </ListItem>
            <ListItem>
              It's a good idea to check your credit report at least once a year to make sure there is no unauthorized activity. See below for information on obtaining a free credit report.
            </ListItem>
          </List>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            Want to learn more?
          </Text>
          <Text>
            Here are some links to learn more about protecting your personal information and preventing identity theft:
          </Text>
          <List>
            <ListItem>
              <Link href='https://www.identitytheft.gov'>FTC Identity Theft Information</Link>
            </ListItem>
            <ListItem>
              <Link href='http://www.microsoft.com/security'>Microsoft Security Home Page (for Windows users)</Link>
            </ListItem>
            <ListItem>
              <Link href='http://www.annualcreditreport.com'>Information on Obtaining Free Credit Reports</Link>
            </ListItem>
          </List>
        </Flex>
      </Flex>
    </Default>
  )
}
