
import {
  Flex,
  Text,
} from 'react-mobi'

import { Printable } from '../layouts'
import { Title } from '../components'
import SchumerBox from '../components/SchumerBox'

export default function Disclosures() {
  const heading = (
    <Title>Our Commitment to Fairness</Title>
  )

  return (
    <Printable heading={heading}>
      <Flex gap='1rem' margin='5rem 3rem' start>
        <Text capitalize>
          FAIR LENDING POLICY STATEMENT
        </Text>
        <Flex start>
          <Text bold>
            General Policy Statement
          </Text>
          <Text>
            MobiLoans, LLC ("Mobiloans") is providing you the following information in a manner consistent with the principles under United States federal law. It is the policy of Mobiloans to make credit products and associated loan operations support services available to all qualified applicants without discriminating on the basis of race, color, religion, national origin, sex, marital status, or age (provided that the applicant has the capacity to enter into a binding contract); the fact that all or part of the applicant's income derives from any public assistance program; or the fact that the applicant has in good faith exercised any right under the Consumer Credit Protection Act.
          </Text>
          <Text>
            It is Mobiloans policy to treat all consumers consistently and fairly. The standard for all employees is to provide service in a fair, equitable, and consistent manner. Mobiloans is committed to making credit products and services available to diverse communities and customers on an equal opportunity basis. This commitment is reflected in formal processes and systems designed to ensure compliance with fair lending requirements. Mobiloans dedicates information and training resources in an ongoing effort to maintain a high level of employee awareness for providing credit products and services in a fair, equitable, and consistent manner.
          </Text>
        </Flex>
        <Flex full>
          <Text capitalize>
            IMPORTANT INFORMATION REGARDING RATES, FEES, AND OTHER COST INFORMATION
          </Text>
          <SchumerBox />
        </Flex>
        <Flex start>
          <Text bold>
            Advertising
          </Text>
          <Text>
            Mobiloans will not engage in advertising practices or other outgoing communications that would discourage on a prohibited basis the making or pursuing of an application for credit.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Notification
          </Text>
          <Text>
            Mobiloans will provide notices of action taken on loan applications in a manner consistent with the principles of the Equal Credit Opportunity Act and Federal Reserve Board Regulation B.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            CREDIT REPORTING DISCLOSURE
          </Text>
          <Text>
            We may make inquiries concerning your credit history and standing, and may report information concerning your performance including, but not limited to, late payments, missed payments, or other defaults, to credit reporting agencies. Late payments, missed payments or other defaults on your Loan may be reflected in your credit report.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            COLLECTION AND USE OF BANK ACCOUNT AND OTHER INFORMATION ABOUT YOU
          </Text>
          <Text>
            As part of our credit underwriting and information collection process, we will consider the bank account information provided by you as eligible for us to process payments against, we may detect additional bank accounts under your ownership, and we also may collect and consider information about you from non-credit reporting agency data providers and publicly available information about you.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            COOKIES AND ONLINE ADVERTISING
          </Text>
          <Text>
            To ensure we are publishing content customers need and want, www.mobiloans.com collects aggregated site visitation statistics using cookies. When someone visits the web site, a cookie is placed on the customer's computer (if the customer accepts cookies). We also advertise our products and services on other websites you might visit. We may use certain third party vendors, including but not limited to, Google, Microsoft, or Yahoo, to place our ads on other websites. These third parties may use the cookies placed on your computer to show you advertisements for our services (based on your prior visits to this website) on other internet websites. You can opt out of Google's, Microsoft's and Yahoo's use of cookies in this manner by visiting their respective advertising opt-out pages. If you choose to not have your browser accept cookies from the www.mobiloans.com web site, you will not be able to subscribe to the service offerings on the site.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Automatic Logging of Session Data
          </Text>
          <Text>
            We automatically log generic information about your computer and your computer's connection to the Internet, which we call "session data". Session data consists of things such as device information, IP address, operating system and browser software information, and the activities conducted by the user while on our site. An IP address is a number that lets computers attached to the Internet, such as our web servers, know where to send data back to the user, such as the pages of the site the user wishes to view. We collect session data because it helps us analyze such things as what items visitors are likely to click on most, the way visitors are clicking through the site, how many visitors are surfing to various pages on the site, how long visitors to the site are staying and how often they are visiting. It also helps us diagnose problems with our servers and lets us better administer our systems. It is possible to determine from an IP address a visitor's Internet Service Provider (ISP) and the approximate geographic location of his or her point of connectivity. We also collect and use session data to help prevent fraud or unauthorized use of our site. Session data is sometimes shared with third parties.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            WEBSITE TERMS OF USE AND PRIVACY POLICY
          </Text>
          <Text>
            PLEASE CAREFULLY REVIEW THESE TERMS AND CONDITIONS OF USE BEFORE USING THIS SITE. ANY USE OF THIS WEBSITE CREATES A BINDING AGREEMENT TO COMPLY WITH THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THESE TERMS, YOU SHOULD EXIT THIS SITE IMMEDIATELY.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            User Agreement
          </Text>
          <Text>
            The following are terms of a legal agreement (the "Agreement") between you and MobiLoans, LLC ("we", "us", or "our") that sets forth the terms and conditions for your use of this web site (the "Site"). This Site is being provided to you expressly subject to this Agreement. By accessing, browsing and/or using this site ("Site"), you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement and to comply with all applicable laws and regulations. The terms and conditions of this Agreement form an essential basis of our bargain.
          </Text>
          <Text>
            We reserve the right to amend this Agreement at any time and will notify you of any such changes by posting the revised Agreement on the Site. You should check this Agreement periodically for changes. All changes shall be effective upon posting. Your continued use of the Site after any change to this Agreement constitutes your agreement to be bound by any such changes. We may terminate, suspend, change, or restrict access to all or any part of this Site without notice or liability.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Limitation of Use
          </Text>
          <Text>
            The copyright in all material on this Site, including without limitation the text, data, articles, design, source code, software, photos, images and other information (collectively the "Content"), is held by Mobiloans or by the original creator of the material and is protected by U.S. and International copyright laws and treaties. You agree that the Content may not be copied, reproduced, distributed, republished, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without the express prior written consent of Mobiloans. You acknowledge that the Content is and shall remain the property of Mobiloans. You may not modify, participate in the sale or transfer of, or create derivative works based on any Content, in whole or in part. The use of the Content on any other Site, including by linking or framing, or in any networked computer environment for any purpose, is prohibited without Mobiloans's prior written approval. You also may not, without Mobiloans's express written permission, "mirror" any material contained on this Site on any other server. Any unauthorized use of any Content on this Site may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications statutes and regulations.
          </Text>
          <Text>
            You agree to use the Content and Site only for lawful purposes. You are prohibited from any use of the Content of Site that would constitute a violation of any applicable law, regulation, rule or ordinance of any national, state, or locality or of any international law or treaty, or that could give rise to any civil or criminal liability. Any unauthorized use of the Site, including but not limited to unauthorized entry into Mobiloans's systems, misuse of passwords, or misuse of any information posted on the Site is strictly prohibited. Mobiloans makes no claims concerning whether the Content may be downloaded or is appropriate for use outside of the United States. If you access this Site from outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction. Your eligibility for particular products or services is subject to final determination by Mobiloans.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Trademarks
          </Text>
          <Text>
            Mobiloans (including the Mobiloans logo), mobiloans.com, and all related logos (collectively the "Mobiloans trademarks") are trademarks, service marks or licensed brands of Mobiloans.com. Other company, product, and service names and logos used and displayed on this Site may be trademarks or service marks owned by Mobiloans or others. Nothing on this Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the Mobiloans trademarks displayed on this Site, without our prior written permission in each instance. You may not use, copy, display, distribute, modify or reproduce any of the trademarks found on the Site unless in accordance with written authorization by us. We prohibit use of any of the Mobiloans trademarks as part of a link to or from any site unless establishment of such a link is approved in writing by us in advance. Any questions concerning any Mobiloans Trademarks, or whether any mark or logo is a Mobiloans Trademark, should be referred to Mobiloans.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Links to Third-Party Sites
          </Text>
          <Text>
            This site may contain links to websites controlled or offered by third-parties (non-affiliates). Mobiloans hereby disclaims liability for any information, materials, products or services posted or offered at any of the third- party sites linked to this website. By creating a link to a third-party website, Mobiloans does not endorse or recommend any products or services offered or information contained at that website, nor is Mobiloans liable for any failure of products or services offered or advertised at those websites. Such third-party may have a privacy policy different from that of Mobiloans and the third-party website may provide less security than the Mobiloans site.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Disclaimer of Warranties
          </Text>
          <Text>
            THE INFORMATION AND MATERIALS CONTAINED IN THIS SITE, INCLUDING TEXT, GRAPHICS, LINKS OR OTHER ITEMS AND CONTENT ARE PROVIDED "AS IS", "AS AVAILABLE," WITHOUT ANY WARRANTY, EITHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR TITLE. ADDITIONALLY, THERE ARE NO WARRANTIES AS TO THE RESULTS OF YOUR USE OF THE CONTENT. MOBILOANS DOES NOT WARRANT THAT THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THIS DOES NOT AFFECT THOSE WARRANTIES WHICH ARE INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
          </Text>
          <Text>
            MOBILOANS MAY DISCONTINUE OR MAKE CHANGES IN THE CONTENT AND SITE AT ANY TIME WITHOUT PRIOR NOTICE TO YOU AND WITHOUT ANY LIABILITY TO YOU. ANY DATED INFORMATION IS PUBLISHED AS OF ITS DATE ONLY, AND MOBILOANS DOES NOT UNDERTAKE ANY OBLIGATION OR RESPONSIBILITY TO UPDATE OR AMEND ANY SUCH INFORMATION. MOBILOANS RESERVES THE RIGHT TO TERMINATE ANY OR ALL SITE OFFERINGS OR TRANSMISSIONS WITHOUT PRIOR NOTICE TO YOU. THIS SITE COULD CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. USE OF THIS SITE IS AT YOUR OWN RISK.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Limitation of Liability
          </Text>
          <Text>
            IN NO EVENT WILL MOBILOANS BE LIABLE FOR ANY DAMAGES INCLUDING GENERAL, SPECIAL, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR ANY OTHER DAMAGES, LOSSES OR EXPENSES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR BUSINESS INTERRUPTION) OF ANY KIND WHETHER IN AN ACTION IN CONTRACT OR NEGLIGENCE ARISING OR RELATING IN ANY WAY TO THE USE OR INABILITY TO USE BY ANY PARTY OF THE CONTENT, THE SITE OR ANY THIRD-PARTY SITE TO WHICH THIS SITE IS LINKED, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE, EVEN IF MOBILOANS, OUR REPRESENTATIVES THEREOF, ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES, OR EXPENSES. [YOUR SOLE REMEDY FOR DISSATISFACTION WITH THIS SITE IS TO STOP USING THE SITE.] IF YOUR USE OF MATERIALS FROM THIS SITE RESULTS IN THE NEED FOR SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR DATA, YOU ASSUME ANY COSTS THEREOF. IF THE FOREGOING LIMITATION IS FOUND TO BE INVALID, YOU AGREE THAT MOBILOANS'S TOTAL LIABILITY FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION OF ANY KIND OR NATURE SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Indemnification
          </Text>
          <Text>
            You agree to indemnify and hold harmless Mobiloans from and against any and all claims, losses, expenses, demands or liabilities, including attorneys' fees and costs, incurred by Mobiloans in connection with any claim by a third party (including any intellectual property claim) arising out of (i) materials and content you submit to, post to or transmit through the Site, or (ii) your use of the Site in violation of this Agreement or in violation of any applicable law. You further agree that you will cooperate fully in the defense of any such claims. Mobiloans reserves the right, at their own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and you shall not in any event settle any such claim or matter without the written consent of Mobiloans.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Monitoring of the Site
          </Text>
          <Text>
            Mobiloans has no obligation to monitor the Site; however, you acknowledge and agree that Mobiloans has the right to monitor the Site electronically from time to time and to disclose any information as necessary or appropriate to satisfy any law, regulation or other governmental request, to operate the Site, or to protect itself or other users of the Site.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Submissions
          </Text>
          <Text>
            All information submitted to Mobiloans via this site shall be deemed and remain the property of Mobiloans and Mobiloans shall be free to use, for any purpose, any idea, concepts, know-how or techniques contained in information a visitor to this site provides Mobiloans through this Site. Mobiloans shall not be subject to any obligations of confidentiality regarding submitted information except as agreed by the Mobiloans entity having the direct customer relationship or as otherwise specifically agreed or required by law. Nothing contained herein shall be construed as limiting or reducing Mobiloans's responsibilities and obligation to customers in accordance with the Mobiloans Privacy Policy.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Use of Personally Identifiable Information
          </Text>
          <Text>
            Mobiloans's practices and policies with respect to the collection and use of personally identifiable information are governed by Mobiloans's Privacy Policy.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Availability
          </Text>
          <Text>
            This site is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to local law or regulation. By offering this site and Content no distribution or solicitation is made by Mobiloans to any person to use the Site or Content in jurisdictions where the provision of the site and/or content is prohibited by law.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Termination
          </Text>
          <Text>
            This Agreement is effective until terminated by Mobiloans. Mobiloans may terminate this Agreement at any time without notice, or suspend or terminate your access and use of the Site at any time, with or without cause, in Mobiloans's absolute discretion and without notice. The following provisions of the Agreement shall survive termination of your use or access to the Site: the sections concerning Indemnification, Disclaimer of Warranties, Limitation of Liability, Waiver, Applicable Law and Dispute Resolution, and General Provisions, and any other provision that by its terms survives termination of your use or access to the Site.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Waiver
          </Text>
          <Text>
            Failure by Mobiloans to enforce any of its rights under this Agreement shall not be construed as a waiver of those rights or any other rights in any way whatsoever.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            COPPA
          </Text>
          <Text>
            COPPA (Children Online Privacy Protection Act): When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online. We do not specifically market to children under 13.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Applicable Law and Dispute Resolution
          </Text>
          <Text>
            This Agreement and all other aspects of your use of the Site shall be governed by and construed in accordance with the laws of the Tunica-Biloxi Tribe of Louisiana and, to the extent applicable, to the laws of the United States. You agree that you will notify Mobiloans in writing of any claim or dispute concerning or relating to the Site and the information or services provided through it, and give Mobiloans a reasonable period of time to address it BEFORE bringing any legal action, either individually, as a class member or representative, or as a private attorney general, against Mobiloans.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Other Agreements
          </Text>
          <Text>
            This Agreement shall be subject to any other agreements you have entered into with Mobiloans.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Additional Terms
          </Text>
          <Text>
            Certain sections or pages on the Site may contain separate terms and conditions of use, which are in addition to the terms and conditions of this Agreement. In the event of a conflict, the additional terms and conditions will govern for those sections or pages.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            Severability
          </Text>
          <Text>
            If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall be enforced to the fullest extent possible, and the remaining provisions of the Agreement shall remain in full force and effect.
          </Text>
        </Flex>
        <Flex start>
          <Text bold>
            General Provisions
          </Text>
          <Text>
            This Agreement supersedes any previous Terms of Use Agreement to which you and Mobiloans may have been bound. This Agreement will be binding on, inure to the benefit of, and be enforceable against the parties and their respective successors and assigns. Neither the course of conduct between parties nor trade practice shall act to modify any provision of the Agreement. All rights not expressly granted herein are hereby reserved. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.
          </Text>
        </Flex>
        <Flex start>
          <Text capitalize>
            COPYRIGHT &copy; 2024. ALL RIGHTS RESERVED
          </Text>
          <Text>
            Copyright in the pages and in the screens displaying the pages, and in the information and material therein and in their arrangement, is licensed by Mobiloans unless otherwise indicated.
          </Text>
        </Flex>
        <Flex start>
          <Text capitalize>
            OFFERS VOID IN SOME STATES
          </Text>
          <Text>
            The offers advertised on this site may be void in some states.
          </Text>
        </Flex>
        <Text>
          Last Updated: July 27, 2021
        </Text>
      </Flex>
    </Printable>
  )
}
