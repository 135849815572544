
import {
  Field,
  Flex,
  Form,
  FormData,
} from 'react-mobi'

import { Default } from '../layouts'
import { Regex } from '../commons'
import { Title } from '../components'

export default function Login() {
  const fields: Array<Field> = [
    {
      autocomplete: 'email',
      error: 'Your email address is required.',
      label: 'Email Address',
      input: 'email',
      name: 'email',
      regex: Regex.email,
      required: true,
    },
    {
      autocomplete: 'current-password',
      error: 'You must provide a valid password.',
      label: 'Password',
      input: 'password',
      name: 'password',
      required: true,
    },
  ]

  function handleSubmit(data: FormData) {
    console.log(data)
  }

  const heading = (
    <Title>Mobiloans Login</Title>
  )

  return (
    <Default heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Form fields={fields} handler={handleSubmit}  />
      </Flex>
    </Default>
  )
}
