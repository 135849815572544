
import { ReactNode } from 'react'

import {
  Background,
  Card,
  Flex,
} from 'react-mobi'

import { Spacer } from '../components'

export interface DefaultProps {
  children: ReactNode
  footer?: ReactNode
  heading: ReactNode
}

export function Default({children, footer, heading}: DefaultProps) {
  return (
    <Flex style={{zIndex: 2}}>
      <Background primary>
        <Flex>
          <Flex margin='0 0 1.5rem 0'>
            {heading}
          </Flex>
          <Card dark width='100%'>
            <Flex full>
              {children}
            </Flex>
          </Card>
          <Spacer />
          {footer}
        </Flex>
      </Background>
    </Flex>
  )
}
