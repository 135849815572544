
import { useEffect, useState } from 'react'

export interface CounterProps {
  amount: number
}

export function Counter({amount}: CounterProps) {
  const [ticker, setTicker] = useState<number>(amount)

  useEffect(() => {
    let timer = setTimeout(() => {
      setTicker(ticker + Math.floor(Math.random() * 100 + 1))
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [ticker])

  const currency: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0,
    style: 'currency',
  })

  return (
    <span>
      {currency.format(ticker)}
    </span>
  )
}
