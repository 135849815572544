
import { useContext } from 'react'

import {
  Body,
  Button,
  Flex,
  Navigation,
  NavigationLink,
} from 'react-mobi'
import { Outlet } from 'react-router-dom'

import { Footer } from './Footer'
import { ProviderContext } from '../commons'

export function Layout() {
  const {isLoggedIn} = useContext(ProviderContext)

  function handleAccount() {
    window.location.href = 'https://mobiloans.com/myaccount'
  }

  function handleApplyNow() {
    window.location.href = 'https://mobiloans.com/apply'
  }

  function handleLogin() {
    window.location.href = 'https://mobiloans.com/authentication/login'
  }

  return (
    <Body>
      <Navigation logo='/images/logo.svg'>
        <Flex row>
          <NavigationLink href='/how-it-works'>How it works</NavigationLink>
          <NavigationLink href='/what-it-costs'>What it costs</NavigationLink>
          <NavigationLink href='/rewards'>Rewards</NavigationLink>
        </Flex>
        <Flex gap='1rem' row>
          {isLoggedIn && <Button callback={handleAccount} label='My Account' secondary />}
          {!isLoggedIn && <Button callback={handleLogin} label='Sign In' />}
          {!isLoggedIn && <Button callback={handleApplyNow} label='Apply Now' secondary />}
        </Flex>
      </Navigation>
      <Outlet />
      <Footer />
    </Body>
  )
}
