
import { useContext } from 'react'

import {
  AmountsTable,
  ChargesTable,
  FeesTable,
  Schumer,
} from 'react-mobi'

import { ProviderContext } from '../commons'

export function SchumerAmounts() {
  const {amounts, charges} = useContext(ProviderContext)

  return (
    <AmountsTable amounts={amounts} charges={charges} />
  )
}

export function SchumerCharges() {
  const {charges} = useContext(ProviderContext)

  return (
    <ChargesTable charges={charges} />
  )
}

export function SchumerFees() {
  const {fees} = useContext(ProviderContext)

  return (
    <FeesTable fees={fees} />
  )
}

export default function SchumerBox() {
  const {apr, charges, fees} = useContext(ProviderContext)


  return (
    <Schumer apr={apr} charges={charges} fees={fees} />
  )
}
