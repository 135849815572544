
import {
  Background,
  Button,
  Card,
  Flex,
  Text,
} from 'react-mobi'

export interface NotificationProps {
  callback: () => void
  message: string
}

export function Notification({callback, message}: NotificationProps) {
  return (
    <Card width='100%'>
      <Flex full start style={{backgroundColor: '#cccccc'}}>
        <Text bold padding='0.5rem 1rem' primary>Important Message</Text>
        <Flex full>
          <Background primary style={{padding: '0.5rem 1rem', width: '100%'}}>
            <Flex gap='0.5rem' padding='0 0 0.5rem 0' start>
              <Text color='#ffffff'>{message}</Text>
              <Button callback={callback} label='Close Message' secondary />
            </Flex>
          </Background>
        </Flex>
      </Flex>
    </Card>
  )
}
