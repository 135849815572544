
import { ReactNode } from 'react'

export interface SubtitleProps {
  children: ReactNode
}

export function Subtitle({children}: SubtitleProps) {
  return (
    <div className='subtitle'>
      {children}
    </div>
  )
}


export interface TitleProps {
  children: ReactNode
}

export function Title({children}: TitleProps) {
  return (
    <div className='title'>
      {children}
    </div>
  )
}
