
import { useContext, useState } from 'react'

import {
  Accordion,
  AccordionItem,
  Flex,
  Formatter,
  Highlight,
  Image,
  Link,
  Option,
  Select,
  Table,
  Text,
} from 'react-mobi'

import { LevelsContext, ProviderContext, RewardsContext } from '../commons'
import { Default } from '../layouts'
import { ContractCell, Email, Phone, Title } from '../components'

export default function FAQ() {
  const {apr, charges, fees} = useContext(ProviderContext)

  const [category, setCategory] = useState<string>('line-of-credit')
  const levels = useContext(LevelsContext)
  const rewards = useContext(RewardsContext)

  function handleCategory(name: string, value: string) {
    setCategory(value)
  }

  const heading = (
    <Title>Frequently Asked Questions</Title>
  )

  return (
    <Default heading={heading}>
      <Flex full gap='5rem' margin='5rem 0' padding='0 2rem' start>
        <Flex full>
          <Select handler={handleCategory} name='category' value={category}>
            <Option value='line-of-credit'>Line of Credit Information</Option>
            <Option value='payment'>Payment Information</Option>
            <Option value='points-and-rewards'>Points & Rewards Information &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Option>
            <Option value='contact'>Contact Information</Option>
          </Select>
        </Flex>
        {category === 'line-of-credit' &&
          <Accordion>
            <AccordionItem title='How does Mobiloans work?'>
              <Text>
                Mobiloans is a flexible line of credit that extends up to $2,500.
              </Text>
            </AccordionItem>
            <AccordionItem title='Who is my Lender?'>
              <Text>
                MobiLoans, LLC, is a tribal lending entity wholly owned by the Tunica-Biloxi Tribe of Louisiana, a federally recognized Indian tribe that operates within the Tribe's reservation.
              </Text>
            </AccordionItem>
            <AccordionItem title='How much money can I get?'>
              <Text>
                You can obtain your available credit by logging on to your account at Mobiloans.com. The credit limit represents the credit we have agreed to extend to you. The available credit is the maximum amount that may be requested as an advance at any time.
              </Text>
            </AccordionItem>
            <AccordionItem title='How do statements work?'>
              <Text>
                Statements are issued 14 days prior to your due date. Email notifications will be issued to the account email on file. Statements will be accessible by logging into your Mobiloans Line of Credit account and selecting 'My Accounts' and clicking 'Statements'.
              </Text>
            </AccordionItem>
            <AccordionItem title='Do you check credit?'>
              <Text>
                Yes, we obtain credit reporting information from various third-party data providers.
              </Text>
            </AccordionItem>
            <AccordionItem title='What fees apply and how do they work?'>
              <Text>
                When you take a draw on your line of credit, you are charged a Cash Advance Fee.
              </Text>
              <Flex padding='2rem'>
                <div className='even'>
                  <Table
                    cols={[
                      {label: 'Draw Amount', name: 'amount'},
                      {label: 'Cash Advance Fee', name: 'fee'},
                    ]}
                    rows={fees.map(({max, min, rate, ...item}) => {
                      let amount = ''
                      if (max && min) {
                        amount = `Over ${Formatter.currency(min)} up to ${Formatter.currency(max)}`
                      } else if (max) {
                        amount = `Up to ${Formatter.currency(max)}`
                      } else if (min) {
                        amount = `Over ${Formatter.currency(min)}`
                      }

                      const fee = `${Formatter.currency(rate)} for each ${Formatter.currency(item.amount)} of cash advanced`
                      return {amount, fee}
                    })} />
                </div>
              </Flex>
              <Text>
                If you need more time to repay your Mobiloans Credit, a minimum charge is calculated on a tiered level based upon the unpaid balance. This fee is a Fixed Finance Charge, and will be charged to your account each billing cycle (<Text bold>approximately 2 weeks</Text>) you have an unpaid balance. Noted below are the outstanding principal balance with the appropriate Fixed Finance Charges.
              </Text>
              <Flex padding='2rem'>
                <div className='even'>
                  <Table
                    cols={[
                      {label: 'If the principal balance as of the last day of your prior Billing Cycle, less the amount of new cash advances made during billing cycle, was...', name: 'amount'},
                      {label: '...your Fixed Finance Charge will be:', name: 'charge'},
                    ]}
                    rows={charges.map(({max, min, ...item}) => {
                      return {
                        amount: `${Formatter.currency(min)} - ${Formatter.currency(min)}`,
                        charge: item.amount > 0 ? Formatter.currency(item.amount) : 'No charge',
                      }
                    })} />
                </div>
              </Flex>
            </AccordionItem>
            <AccordionItem title='What is the Annual Percentage Rate (APR) and how is it calculated?'>
              <Text>
                The annual percentage rate (APR) on Mobiloans cash advanced ranges between <Highlight>{Formatter.percentage(apr.min)} - {Formatter.percentage(apr.max)}</Highlight>.  This APR will vary based on the amount of the line of credit and the maximum number of billing cycles available for payment. See <Link href='/terms-and-conditions'>Important Truth in Lending Disclosures</Link>.<br />
                <br />
                APR is calculated using the following method:<br />
                <br />
                <Text bold>APR</Text> = ((Total Fees/Avg. Principal Balance)/Number of Billing Cycles) * Billing Cycles per year * 100<br />
                <br />
                <Text bold>Total Fees</Text> = Sum of all Cash Advance Fees + Sum of all Fixed Finance Changes<br />
                <br />
                <Text bold>Average Principal Balance</Text> = the sum of each principal balance at the end of each period divided by the number of payments<br />
                <br />
                <Text bold>Number of Billing Cycles</Text> = the total number of payments<br />
                <br />
                <Text bold>Billing Cycles per year</Text> = Biweekly/Weekly (you are paid weekly or every two weeks) = 26 and Monthly/Twice monthly (you are paid monthly or two times a month) = 24<br />
                <br />
                <ContractCell type='CashAdvFeeExample' /><br />
              </Text>
            </AccordionItem>
            <AccordionItem title='What states are currently NOT serviced by Mobiloans?'>
              <Text>
                Arizona, Arkansas, Colorado, Connecticut, District of Columbia, Georgia, Illinois, Louisiana, Maine, Maryland, Massachusetts, Minnesota, Montana, New Hampshire, New Jersey, New York, North Carolina, Pennsylvania, South Dakota, Vermont, Virginia and West Virginia.
              </Text>
              <Flex>
                <Image src='/images/service-map.png' />
              </Flex>
            </AccordionItem>
          </Accordion>
        }
        {category === 'payment' &&
          <Accordion>
            <AccordionItem title='What is my minimum repayment amount and how is it calculated?'>
              <Text>
                Your periodic statement will provide the minimum amount you must pay for that billing cycle. The minimum payment amount is equal to the minimum principal amount for your line plus the Cash Advance Fees and Fixed Finance Charges that apply. For draws of $400 or less, the minimum amount you are required to pay is $16 of principal plus the Cash Advance Fees and Fixed Finance Charges that apply. For draws of more than $400 up to $899.99, the minimum principal amount you are required to repay is 4% of the outstanding principal amount on the statement after the most recent draw, plus applicable Cash Advance Fees and Fixed Finance Charges. For draws of $900 - $999.99, the minimum principal percentage you are required to pay is 3.8467%. For draws of $1,000 - $1,099.99, the minimum principal percentage you are required to pay is 3.5710%. For draws of $1,100 - $1,199.99, the minimum principal percentage you are required to pay is 2.6316%. For draws of $1,200 - $1,299.99, the minimum principal percentage you are required to pay is 2.5000%. For draws of $1,300 - $1,399.99, the minimum principal percentage you are required to pay is 2.3808%. For draws of $1,400 - $1,499.99, the minimum principal percentage you are required to pay is 2.2222%. For draws of $1,500 - $1,999.99, the minimum principal percentage you are required to pay is 2.0000%. For draws of $2,000 - $2,500, the minimum principal percentage you are required to pay is 1.6667%. Remember, with Mobiloans there are no prepayment penalties, so you can pay off your line of credit at any time and only be charged fees for the time you have an outstanding balance.<Text super>1</Text>
              </Text>
            </AccordionItem>
            <AccordionItem title='How do I payback my Line of Credit?'>
              <Text>
                <Link href='/what-it-costs'>Example Payment Schedule</Link><br />
                Easy... if you have authorized ACH payments from your checking account (AutoPay), payments will automatically be drafted in an amount equal to the minimum payment amount. Or you can remit payments as permitted by the <Link href='/terms-and-conditions'>Terms and Conditions</Link>.
              </Text>
            </AccordionItem>
            <AccordionItem title='What is my Pay Frequency?'>
              <Text>
                Pay Frequency means the frequency you receive your income payments which are either, weekly, bi-weekly, semi-monthly, or monthly. If your Pay Frequency is weekly, your Pay Dates are considered to be bi-weekly for determining your Billing Cycle under this Agreement. If your Pay Frequency is monthly, your Pay Dates are considered to be semi-monthly for determining your Billing Cycle under this agreement. All pay frequencies are required to make two minimum amount due payments per month.
              </Text>
            </AccordionItem>
            <AccordionItem title='What is AutoPay?'>
              <Text>
                AutoPay is the automatic payment of your minimum payment amount from your checking account.
              </Text>
            </AccordionItem>
          </Accordion>
        }
        {category === 'points-and-rewards' &&
          <Accordion>
            <AccordionItem title='What are Mobiloans Points?'>
              <Text>
                The Mobiloans Rewards program allows you to receive Points by participating in various activities and performing specific transactions with your Mobiloans Credit Account, such as timely and successfully making your minimum due payments. As you accumulate Points, you will earn Reward Discounts that will reduce the standard Cash Advance Fees and Fixed Finance Charges on your Mobiloans cash advances1. Visit My Account to see your current Point balance. <Link href='/rewards-terms-and-conditions'>Click here</Link> to view the Mobiloans Rewards Program Terms and Conditions.
              </Text>
            </AccordionItem>
            <AccordionItem title='How do I earn points?'>
              <Text>
                There are a few ways you can earn points. Currently, you have the opportunities listed below for earning points. The maximum number of points that can be earned per calendar month is 2,000. We are always thinking of new ways you can earn points with Mobiloans, so check back here often for additional earning opportunities.
              </Text>
              <Flex padding='2rem'>
                <div className='odd'>
                  <Table
                    cols={[
                      {label: 'Points*', name: 'points'},
                      {label: 'Activity', name: 'action'},
                      {label: 'Description', name: 'description'},
                    ]}
                    rows={rewards.map(reward => ({
                      ...reward,
                      points: Formatter.number(reward.points),
                    }))} />
                </div>
              </Flex>
              <Text italic>
                *These point values are subject to change at MobiLoans, LLC sole discretion. MobiLoans, LLC may also issue Points for other activities on an ad hoc or promotional basis from time to time in its discretion.
              </Text>
            </AccordionItem>
            <AccordionItem title='What are the reward tiers and what discounts apply?'>
              <Flex padding='2rem'>
                <div className='odd'>
                  <Table
                    cols={[
                      {label: 'Reward Level', name: 'name'},
                      {label: 'Points Required', name: 'points'},
                      {label: 'Points Discount*', name: 'discount'},
                    ]}
                    rows={levels.map(level => ({
                      ...level,
                      discount: (
                        <Text>
                          {Formatter.percentage(level.discount, 0)} reduction of the standard Cash Advance Fee and Fixed Finance Charge.<Text super>1</Text>
                        </Text>
                      ),
                      name: level.name.charAt(0).toUpperCase() + level.name.slice(1),
                      points: Formatter.number(level.points),
                    }))} />
                </div>
              </Flex>
              <Text italic>
                *Reward Discount thresholds are subject to change at MobiLoans LLC sole discretion.
              </Text>
              <Text>
                The Reward Discount shown in the chart above will apply to reduce the standard Cash Advance Fee and Fixed Finance Charges<Text super>1</Text> by the percentage of the Reward Discount. For example, if the standard Fixed Finance Charge<Text super>1</Text> to be assess on your outstanding principal balance was seventy-five dollars ($75) and you achieved thirty thousand (30,000) points and a twenty percent (20%) Rewards Discount, your new Fixed Finance Charge<Text super>1</Text> for the applicable balance would be sixty dollars ($60).
              </Text>
            </AccordionItem>
            <AccordionItem title='I achieved a tier but do not see the discount in My Account or on My Rewards tab?'>
              <Text>
                You may have pending points. If you log in to the My Account page and determine based on your transaction history that you should be in a different tier or are missing points, you may reach out to customer support at <Phone number='877-836-1518' />.
              </Text>
            </AccordionItem>
            <AccordionItem title='I received an email on my Mobiloans Reward information. What does it mean?'>
              <Text>
                Receiving emails about Mobiloans Rewards just means you are enrolled in the program and will receive emails about your Point balance and accrual history and the Reward Level you have achieved. You may opt out of these emails any time by clicking the 'Email Opt-out' link within the email or managing your contact preferences by logging in to My Account or calling our customer support at <Phone number='877-836-1518' />.
              </Text>
            </AccordionItem>
            <AccordionItem title='Where can I locate my current Reward Level and see how many Points I need to get to the next Reward Level?'>
              <Text>
                It's easy... you can log in to My Account and access the 'My Rewards' tab. You will see your Point balance and accrual history and the Reward Level you have achieved, along the number of Points required before you achieve the next Reward Level.
              </Text>
            </AccordionItem>
            <AccordionItem title='How often are my Points updated? I see "Pending" - what does that mean?'>
              <Text>
                Your Point balance is updated based on your Points-earning activity (see the calendar chart above - click on the dates to see when changes in points affect changes in discounts). For example, if you recently paid your minimum due payment in full and on time, you may notice on your most recent payment that the Points show as pending. Those Points will be fully posted to your Points balance seven days after the date you made your payment.
              </Text>
            </AccordionItem>
            <AccordionItem title='I missed a payment - does my discount go away? When can I begin earning points again?'>
              <Text>
                We will never negatively change your Reward Level. After missing a payment, you will need to make three successful payments so that you can regain your eligibility to resume earning points.
              </Text>
            </AccordionItem>
          </Accordion>
        }
        {category === 'contact' &&
          <Accordion>
            <AccordionItem title='What do I need to do to verify my account information?'>
              <Text>
                If you have received a request for documents to verify your account information, you can email scanned documents to <Email address='verify@mobiloans.com' /> or fax the information to <Phone number='877-891-7862' />.
              </Text>
            </AccordionItem>
            <AccordionItem title='Why do I not receive emails about my Line of Credit?'>
              <Text>
                Due to email filters and other technology services used to curb SPAM, your internet service provider may prevent you from receiving emails regarding your account with Mobiloans.com. However, most emails received from Mobiloans.com are very important and include account updates and statements. Most of these emails are directed to a bulk/junk folder. To ensure that you receive future notices and email communication please take a moment to add our email address, admin@mobiloans.com, to your address book or inbox 'safe list'.
              </Text>
            </AccordionItem>
            <AccordionItem title='To Contact Us'>
              <Flex autoWrap row start>
                <Flex full start>
                  <Text bold>
                    For Payments Mail To:
                  </Text>
                  <Text>
                    Make checks payable to<br />
                    MobiLoans, LLC<br />
                    P.O. Box 42917<br />
                    Philadelphia, PA 19101<br />
                  </Text>
                </Flex>
                <Flex full start>
                  <Text bold>
                    For Customer Support:
                  </Text>
                  <Text>
                    Attn Mobiloans Customer Support:<br />
                    MobiLoans, LLC<br />
                    P.O. Box 1409<br />
                    Marksville, LA 71351<br />
                    P: <Phone number='877-836-1518' /><br />
                    F: <Phone number='877-891-7862' /><br />
                    Hours of operation:<br />
                    &nbsp;&nbsp; Monday - Friday 7 AM to 7 PM CT<br />
                    Email: <Email address='support@mobiloans.com' /><br />
                  </Text>
                </Flex>
                <Flex full start>
                  <Text bold>
                    Physical Address:
                  </Text>
                  <Text>
                    MobiLoans, LLC<br />
                    151 Melacon Road<br />
                    Marksville, LA 71351<br />
                  </Text>
                </Flex>
              </Flex>
            </AccordionItem>
          </Accordion>
        }
      </Flex>
    </Default>
  )
}
