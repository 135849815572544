
import { useState } from 'react'

import {
  Button,
  Field,
  Flex,
  Form,
  FormData,
  Text,
} from 'react-mobi'

import { Default } from '../layouts'
import { Title } from '../components'

export default function ContactUs() {
  const [data, setData] = useState<FormData>({})

  function handleChange(data: FormData) {
    setData(data)
    console.log(data)
  }

  function handleSubmit() {

  }

  const fields: Array<Field> = [
    {
      autocomplete: 'email',
      error: 'Your email address is required.',
      label: 'Your Email Address',
      input: 'email',
      name: 'email',
      regex: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/, 'i'),
      required: true,
      value: data['email'] || undefined,
    },
    {
      autocomplete: 'name',
      error: 'Your full name is required.',
      label: 'Full Name',
      input: 'text',
      name: 'name',
      required: true,
      value: data['name'] || undefined,
    },
    {
      autocomplete: 'tel-national',
      error: 'Your phone number is required.',
      label: 'Home Phone',
      input: 'tel',
      name: 'phone',
      required: true,
      value: data['phone'] || undefined,
    },
    {
      error: 'You must select your state of residence.',
      label: 'State of Residence',
      input: 'select',
      name: 'state',
      options: {
        AK: 'AK',
        AL: 'AL',
        AR: 'AR',
        AS: 'AS',
        AZ: 'AZ',
        CA: 'CA',
        CO: 'CO',
        CT: 'CT',
        DC: 'DC',
        DE: 'DE',
        FL: 'FL',
        GA: 'GA',
        GU: 'GU',
        HI: 'HI',
        IA: 'IA',
        ID: 'ID',
        IL: 'IL',
        IN: 'IN',
        KS: 'KS',
        KY: 'KY',
        LA: 'LA',
        MA: 'MA',
        MD: 'MD',
        ME: 'ME',
        MI: 'MI',
        MN: 'MN',
        MO: 'MO',
        MP: 'MP',
        MS: 'MS',
        MT: 'MT',
        NC: 'NC',
        ND: 'ND',
        NE: 'NE',
        NH: 'NH',
        NJ: 'NJ',
        NM: 'NM',
        NV: 'NV',
        NY: 'NY',
        OH: 'OH',
        OK: 'OK',
        OR: 'OR',
        PA: 'PA',
        PR: 'PR',
        RI: 'RI',
        SC: 'SC',
        SD: 'SD',
        TN: 'TN',
        TX: 'TX',
        UM: 'UM',
        UT: 'UT',
        VA: 'VA',
        VI: 'VI',
        VT: 'VT',
        WA: 'WA',
        WI: 'WI',
        WV: 'WV',
        WY: 'WY',
      },
      required: true,
      value: data['state'] || undefined,
    },
    {
      error: 'A message subject must be selected.',
      label: 'Subject of Message',
      input: 'select',
      name: 'subject',
      options: {
        'Cancel my payment or line of credit': 'Cancel my payment or line of credit',
        'Explanation of declined application': 'Explanation of declined application',
        'Help with logging in/application': 'Help with logging in/application',
        'How do I make a payment': 'How do I make a payment',
        'How do I update my application': 'How do I update my application',
        'Increase my line of credit': 'Increase my line of credit',
        'Other': 'Other',
        'Past due account payment arrangements': 'Past due account payment arrangements',
        'Privacy/Security of my information': 'Privacy/Security of my information',
        'Referrals': 'Referrals',
        'Returned payment/past due account': 'Returned payment/past due account',
        'Status of my faxed documents': 'Status of my faxed documents',
        'Status of my line of credit or application': 'Status of my line of credit or application',
      },
      required: true,
      value: data['subject'] || undefined,
    },
    {
      autocomplete: 'off',
      error: 'Your must provide a message.',
      label: 'Message',
      input: 'textarea',
      name: 'message',
      required: true,
      value: data['message'] || undefined,
    },
  ]

  const heading = (
    <Title>Contact Us</Title>
  )

  return (
    <Default heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Flex autoWrap between full gap='3rem' row start>
          <Flex gap='1rem' start>
            <Text bold primary size='1.5rem'>
              Contact Us
            </Text>
            <Flex start>
              <Text bold primary>
                Phone Numbers
              </Text>
              <Text>
                Customer Support: 877-836-1518
              </Text>
              <Text>
                General Fax: 877-891-7862
              </Text>
            </Flex>
            <Flex start>
              <Text bold primary>
                Customer Support Hours
              </Text>
              <Text>
                Automated customer support is available 24 hours a day.<br />
                Live customer support is available
              </Text>
              <Text bold>
                7:00 AM - 7:00 PM CT, Monday - Friday
              </Text>
              <Text bold>
                7:00 AM - 3:00 PM CT, Saturday
              </Text>
            </Flex>
            <Flex start>
              <Text bold primary>
                Payment Address
              </Text>
              <Text>
                Make checks payable to MobiLoans, LLC<br />
                P.O. Box 42917<br />
                Philadelphia, PA 19101<br />
              </Text>
            </Flex>
            <Flex start>
              <Text bold primary>
                Mailing Address
              </Text>
              <Text>
                For questions, please contact us at the address below:<br />
                MobiLoans, LLC<br />
                P.O. Box 1409<br />
                Marksville, LA 71351<br />
              </Text>
            </Flex>
            <Flex start>
              <Text bold primary>
                Physical Address
              </Text>
              <Text>
                MobiLoans, LLC<br />
                151 Melacon Drive<br />
                Marksville, LA 71351<br />
              </Text>
            </Flex>
          </Flex>
          <Flex start>
            <Text bold padding={0} primary>
              To contact us by email, fill out this form:
            </Text>
            <Form fields={fields} handler={handleChange} hideActions />
            <Text>
              To help us verify you are a real person, please check the box below.<br />
              <iframe title="reCAPTCHA" width="304" height="78" role="presentation" name="a-qkp97ph7n38l" frameBorder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Lc0EAoTAAAAAKU08F5qtiygK_Kiq775A8GrgVKc&amp;co=aHR0cHM6Ly9tb2JpbG9hbnMuY29tOjQ0Mw..&amp;hl=en&amp;v=hfUfsXWZFeg83qqxrK27GB8P&amp;size=normal&amp;cb=w59ilnuwvwzs"></iframe>
            </Text>
            <Button callback={handleSubmit} label='Submit' primary />
          </Flex>
        </Flex>
      </Flex>
    </Default>
  )
}
