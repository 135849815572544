
import { Regex, StatesContext } from '../commons'
import { useContext, useState } from 'react'

import {
  Field,
  Flex,
  Form,
  FormData,
  Modal,
  Stepper,
  StepperItem,
  Text,
} from 'react-mobi'

import { Default } from '../layouts'
import { Service } from '../commons'
import { Subtitle, Title } from '../components'

export default function ApplyNow() {
  const [data, setData] = useState<FormData>({})
  const [message, setMessage] = useState<null | string>(null)
  const states = useContext(StatesContext)

  const fields1: Array<Field> = [
    {
      error: 'You must select your state of residence.',
      label: 'State of Residence',
      input: 'select',
      name: 'state',
      options: states.reduce((map, state) => ({...map, [state]: state}), {}),
      required: true,
      value: data['state'] || undefined,
    },
  ]

  const fields: Array<Field> = [
    {
      autocomplete: 'given-name',
      error: 'Your first name is required.',
      label: 'First Name',
      input: 'text',
      name: 'firstName',
      required: true,
      value: data['firstName'] || undefined,
    },
    {
      autocomplete: 'additional-name',
      label: 'Middle Name',
      input: 'text',
      name: 'middleName',
      value: data['middleName'] || undefined,
    },
    {
      label: 'Suffix',
      input: 'select',
      name: 'suffix',
      options: {
        I: 'I',
        II: 'II',
        III: 'III',
        IV: 'IV',
        V: 'V',
        VI: 'VI',
        VII: 'VII',
        VIII: 'VIII',
        IX: 'IX',
        X: 'X',
        Jr: 'Jr',
      },
      value: data['suffix'] || undefined,
    },
    {
      autocomplete: 'family-name',
      error: 'Your last name is required.',
      label: 'Last Name',
      input: 'text',
      name: 'lastName',
      required: true,
      value: data['lastName'] || undefined,
    },
    {
      autocomplete: 'bday',
      error: 'Your birthday is required.',
      label: 'Date of Birth',
      input: 'date',
      name: 'dateOfBirth',
      regex: Regex.dob,
      required: true,
      value: data['dateOfBirth'] || undefined,
    },
    {
      autocomplete: 'email',
      error: 'Your email address is required.',
      label: 'Email Address',
      input: 'email',
      name: 'email',
      regex: Regex.email,
      required: true,
      value: data['email'] || undefined,
    },
    {
      autocomplete: 'new-password',
      error: 'You must provide a valid password.',
      label: 'Password',
      input: 'password',
      name: 'password',
      required: true,
      value: data['password'] || undefined,
    },
    {
      autocomplete: 'new-password',
      error: 'Your passwords do not match.',
      label: 'Confirm Password',
      input: 'password',
      name: 'confirmPassword',
      required: true,
      value: data['confirmPassword'] || undefined,
    },
    {
      label: 'By clicking here, I acknowledge that I have read and understood the Mobiloans Privacy Policy.',
      input: 'checkbox',
      name: 'privacyPolicy',
      required: true,
      value: data['privacyPolicy'] || undefined,
    },
  ]

  function handleModal(yes: boolean) {
    setMessage(null)
  }

  function handleRegister() {
    console.log('Registered!', data)
  }

  function handleSubmit(data: FormData) {
    setData(data)
    console.log(data)
  }

  async function handleStep1() {
    if (!data.state) return false

    // const supported = await Service.checkState(data['state'] as string)
    // return supported
    return true
  }

  const heading = (
    <>
      <Title>
        Welcome to Mobiloans!
      </Title>
      <Subtitle>
        We will need to collect a couple of pieces of information so that you can start your secure application. You're a few short steps away from finding out how much cash you can get.
      </Subtitle>
    </>
  )

  return (
    <Default heading={heading}>
      <Modal callback={handleModal} open={!!message} yes='OK'>
        {message}
      </Modal>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Stepper finish={handleRegister} nextLabel='Continue'>
          <StepperItem callback={handleStep1} title='Verify Service'>
            <Flex start>
              <Text bold primary>
                Please verify we lend in your state
              </Text>
              <Form
                fields={fields1}
                handler={handleSubmit}
                hideActions
                highlight='optional' />
            </Flex>
          </StepperItem>
          <StepperItem callbackLabel='Continue' title='Create Your Account'>
            <Flex start>
              <Text bold primary>
                Your Details
              </Text>
              <Text bold primary>
                Account Information
              </Text>
              <Form
                fields={fields}
                handler={handleSubmit}
                hideActions
                highlight='optional' />
            </Flex>
          </StepperItem>
          <StepperItem title='Personal Information'>
            TODO
          </StepperItem>
          <StepperItem title='Financial Information'>
            TODO
          </StepperItem>
          <StepperItem title='Submit Application'>
            TODO
          </StepperItem>
        </Stepper>
      </Flex>
    </Default>
  )
}
