
import { useContext, useEffect, useState } from 'react'

import {
  Background,
  Button,
  Card,
  Checkbox,
  Flex,
  Formatter,
  H2,
  Input,
  Option,
  Select,
  Table,
  Text,
} from 'react-mobi'

import { LevelsContext, ProviderContext } from '../commons'

export interface CalculatorProps {
}

export function Calculator({}: CalculatorProps) {
  const {amounts, charges, fees} = useContext(ProviderContext)
  const levels = useContext(LevelsContext)

  const loan = []
  for (let i = 0; i <= 2500; i += 100) {
    loan.push(i)
  }

  const [amount, setAmount] = useState<number>(0)
  const [balance, setBalance] = useState<number>(0)
  const [enabled, setEnabled] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [rewards, setRewards] = useState<number>(0)
  const [rows, setRows] = useState<Array<Record<string, number | string>>>([])
  const [show, setShow] = useState<boolean>(false)

  const [apr, setApr] = useState<number>(0)
  const [average, setAverage] = useState<number>(0)
  const [cycles, setCycles] = useState<number>(0)
  const [paid, setPaid] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)

  // Calculate the cash advance fee (CAF) based on the amount drawn
  function calcCAF(amount: number): number {
    for (let i = fees.length-1; i >= 0; i--) {
      if (fees[i].min && amount > (fees[i].min as number)) {
        return (amount / fees[i].amount) * fees[i].rate
      }
    }
    return (amount / fees[0].amount) * fees[0].rate
  }

  // Calculate rewards discount percentage
  function calcDiscount(points: number): number {
    for (let i = levels.length-1; i >= 0; i--) {
      if (points >= levels[i].points) {
        return levels[i].discount
      }
    }
    return 0
  }

  // Calculate the Equal Payment Amount (EPA) percentage based on the current principal balance
  function calcEPA(balance: number): number {
    for (let i = amounts.length-1; i >= 0; i--) {
      if (balance >= (amounts[i].min as number)) {
        return amounts[i].percentage as number
      }
    }
    return 0
  }

  // Calculate the fixed finance charge (FFC) based on the current principal balance
  function calcFFC(balance: number): number {
    for (let i = 0; i < charges.length; i++) {
      if (balance >= charges[i].min && balance <= charges[i].max) {
        return charges[i].amount
      }
    }
    return 0
  }

  function changeAmount(name: string, value: string) {
    setAmount(Number(value))
    setRows([])
    setShow(false)
  }

  function changeBalance(name: string, value: string) {
    setBalance(Number(value))
    setRows([])
    setShow(false)
  }

  function changeEnabled(name: string, value: string) {
    setEnabled(value === 'true')
    setRows([])
    setShow(false)
  }

  function changeRewards(name: string, value: string) {
    setRewards(Number(value))
    setRows([])
    setShow(false)
  }

  function handleCalculate() {
    // Validate
    if ((amount + balance) > 2500) {
      setError('Total amount borrowed cannot exceed $2,500.')
      return
    } else if ((amount + balance) < 0) {
      setError('Total amount borrowed cannot be negative.')
      return
    } else if (balance > 2500) {
      setError('Principal Balance before Cash Advance cannot exceed $2,500.')
      return
    } else if (balance < 0) {
      setError('Principal Balance before Cash Advance cannot be negative.')
      return
    } else if (amount === 0) {
      setError('Cash Advance Amount must be set')
      return
    } else if ((amount + balance) === 0) {
      setError('Total amount borrowed cannot be $0.')
      return
    }

    // Calculate the number of billing periods per year
    const _periods = 26 // biweekly
    // Calculate the total amount
    const _total = amount + balance

    // Calculate the cash advance fee (caf)
    const _caf = calcCAF(_total)
    // Calculate the equal payment amount (epa)
    const _epa = calcEPA(_total)
    // Calculate minimum principal amount (ppp)
    const _ppp = _epa === 0 ? 16 : _total * _epa

    // Calculate periodic principal payment amount
    const varPeriodicPrincipalPayment = _epa === 0 ? amounts[0].payment as number : _total * _epa

    // Calculate the variable CAF
    let varCaf = calcCAF(amount) * (enabled ? (1 - calcDiscount(rewards)) : 1)

    // Calculate the number of payments
    const _cycles = _total > 400 ? Math.ceil(_total / varPeriodicPrincipalPayment) : (Math.floor(_total / 16) + 1)

    // Calculate the total of all payments.  Iterate through each payment and calc the FFC and rewards discount as principal declines and rewards points are added.
    let _paid = 0

    for (let i = 0; i < _cycles; i++) {
        // Calculate the finance charge (FFC) for the current principal balance first period always has CAF instead of FFC so FFC is zero for first payment
        let currentFFC = (i === 0 ? 0 : calcFFC(_total - (varPeriodicPrincipalPayment * i)))

        // Discount the ffc payment for the period by the current reward. Set current reward to starting reward points + (500 for each period to the current period)
        currentFFC = currentFFC * (enabled ? (1 - calcDiscount(rewards + (i * 500))) : 1)

        // Calculate the total payment for the current period
        const totalPayment = varPeriodicPrincipalPayment + currentFFC + (i === 0 ? varCaf : 0)

        // accumulate varTotalPayment
        _paid += totalPayment
    }

    // Calculate APR - explicitly assumes all periods are 2 weeks
    const _average = (varPeriodicPrincipalPayment + _total) / 2 //average is always half of the total amount borrowed + one payment
    const totalFees = (_paid - _total)
    const _apr = (((totalFees / _average) / _cycles) * _periods)

    // Set the state with the calculated values
    // always set state values at end because state will update asynchronously
    // and values cannot be relied on in successive statements in the same code block.

    const _rows: Array<Record<string, number | string>> = []
    for (let i = 0; i < _cycles; i++) {
      const rewardPoints = rewards + (i * 500)
      const currentFFC = i === 0 ? 0   : calcFFC(_total - (varPeriodicPrincipalPayment * i)) * (enabled ? (1 - calcDiscount(rewardPoints)) : 1)
      const currentCAF = i === 0 ? varCaf : 0  //caf pulled from state so rewards discount is already accounted for anti pattern from prior line but FFC can only be calculated by each period

      let pmt = Math.min(((varPeriodicPrincipalPayment - 0) + currentFFC + currentCAF), (_total - (varPeriodicPrincipalPayment * i)+ currentFFC))
      let ppp = Math.min(varPeriodicPrincipalPayment, (_total - (varPeriodicPrincipalPayment * i)))
      let principal = Math.max(_total - (varPeriodicPrincipalPayment * (i+1)), 0)
      if (i === _cycles && principal !== 0) {
        // Ensure any remaining principal is captured in last payment
        pmt = pmt + principal
        ppp = ppp + principal
        principal = 0
      }

      // Populate _rows
      _rows.push({
        cycle: i + 1,
        ppp,
        caf: currentCAF,
        ffc: currentFFC,
        pmt,
        principal,
        rewards: (enabled ? (rewards + ((i + 1) * 500) + (i === (_cycles - 1) ? 500 : 0)) : 0),
      })
    }

    setApr(_apr)
    setAverage(_average)
    setCycles(_cycles)
    setError('')
    setPaid(_paid)
    setRows(_rows)
    setTotal(_total)
  }

  useEffect(() => {
    setShow(rows.length > 0)
  }, [rows, setShow])

  return (
    <div className="calculator" key={rows.length}>
      <Card>
        <Flex autoWrap full row stretch>
          <Background primary>
            <Flex gap='1rem' start style={{margin: '1rem'}}>
              <Flex start>
                <H2>Loan Calculator</H2>
                <Text color='#ffffff'>Calculator loan costs and fees</Text>
              </Flex>
              <Flex start>
                <Text color='#ffffff'>What is the Principal balance before the Cash Advance?</Text>
                <Input handler={changeBalance} name='balance' value={`${balance}`} />
              </Flex>
              <Flex start>
                <Text color='#ffffff'>Select the new Cash Advance amount</Text>
                <Select handler={changeAmount} name='amount' value={`${amount}`}>
                  {loan.map(amount => (
                    <Option key={amount} value={`${amount}`}>{Formatter.currency(amount)}</Option>
                  ))}
                </Select>
              </Flex>
              <Flex start>
                <Text color='#ffffff'>What is the rewards points balance at the time of the Cash Advance?</Text>
                <Input disabled={!enabled} handler={changeRewards} name='rewards' type='number' value={`${rewards}`} />
                <Checkbox checked={enabled} handler={changeEnabled} name='enabled'>Calculate discount</Checkbox>
              </Flex>
              <Flex row>
                <Button callback={handleCalculate} label='Calculate' secondary />
              </Flex>
            </Flex>
          </Background>
          <Flex full style={{padding: '2rem'}}>
            {error !== '' &&
              <Text danger>{error}</Text>
            }
            {show &&
              <Flex style={{height: '100%', width: '100%'}}>
                <Flex between full row>
                  <Text>New Starting Principal Balance:</Text>
                  <Text>{Formatter.currency(total)}</Text>
                </Flex>
                <Flex between full row>
                  <Text>Total Payments:</Text>
                  <Text>{Formatter.currency(paid)}</Text>
                </Flex>
                <Flex between full row>
                  <Text>Total Billing Cycles:</Text>
                  <Text>{cycles}</Text>
                </Flex>
                <Flex between full row>
                  <Text>Approx Months To Pay Off:</Text>
                  <Text>{cycles / 2}</Text>
                </Flex>
                <Flex between full row>
                  <Text>Average Principal Balance:</Text>
                  <Text>{Formatter.currency(average)}</Text>
                </Flex>
                <Flex between full row>
                  <Text>Annual Percentage Rate (APR):</Text>
                  <Text>{Formatter.percentage(apr)}</Text>
                </Flex>
              </Flex>
            }
          </Flex>
        </Flex>
      </Card>
      {show &&
        <Table
          card
          cols={[
            {label: 'Billing Cycle', name: 'cycle'},
            {label: 'Minimum Principal Payment', name: 'ppp'},
            {label: 'Cash Advance Fee', name: 'caf'},
            {label: 'Fixed Finance Charge', name: 'ffc'},
            {label: 'Total Minimum Payment', name: 'pmt'},
            {label: 'Principal', name: 'principal'},
            {label: 'Rewards', name: 'rewards'},
          ]}
          footer={
            <Text color='#ffffff' size='0.9rem'>
              *Please note that this is an example and actual amounts may vary slightly based on your account balance and billing at the time of the new cash advance.
            </Text>
          }
          rows={rows.map(row => ({
            ...row,
            ppp: Formatter.currency(row.ppp as number),
            caf: Formatter.currency(row.caf as number),
            ffc: Formatter.currency(row.ffc as number),
            pmt: Formatter.currency(row.pmt as number),
            principal: Formatter.currency(row.principal as number),
            rewards: row.rewards === 0 ? 'N/A' : row.rewards,
          }))} />
      }
    </div>
  )
}
