
import { Link } from 'react-mobi'

export interface EmailProps {
  address?: string
}

export function Email({address = ''}: EmailProps) {
  return (
    <Link href={`mailto:${address}`}>
      {address}
    </Link>
  )
}

export interface PhoneProps {
  number?: string
}

export function Phone({number = ''}: PhoneProps) {
  return (
    <Link href={`tel:+1${number.replace(/-/g, '')}`}>
      {number}
    </Link>
  )
}

export interface WebsiteProps {
  route?: string
}

export function Website({route = ''}: WebsiteProps) {
  return (
    <Link href={`https://www.mobiloans.com${route}`}>
      https://www.mobiloans.com{route}
    </Link>
  )
}
