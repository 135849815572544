
import { useContext } from 'react'

import { ProviderContext } from '../commons'

export interface ContractCellProps {
  type: string
}

export function ContractCell({type}: ContractCellProps) {
  const {text} = useContext(ProviderContext)

  return (
    <span dangerouslySetInnerHTML={{__html: text[type] || 'Unknown'}} />
  )
}
