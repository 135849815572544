
import { ReactNode, useContext, useEffect, useState } from 'react'

import { Cookie } from 'react-mobi'
import { DataContext, ProviderContext, VersionContext } from '../commons'

export interface ProviderProps {
  children: ReactNode
}

export function Provider({children}: ProviderProps) {
  const data: Record<string, any> = useContext(DataContext)
  const defaultVersion: string = useContext(VersionContext)

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [time, setTime] = useState<number>(0)
  const [version, setVersion] = useState<string>(defaultVersion)

  useEffect(() => {
    let timer = setTimeout(() => {
      const v = Cookie.get('ElaLogin')
      setIsLoggedIn(!!v)
      setTime((new Date()).getTime())
      setVersion(v || defaultVersion)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [defaultVersion, isLoggedIn, time, version])

  return (
    <ProviderContext.Provider value={{
      amounts: data[version].amounts,
      apr: data[version].apr,
      charges: data[version].charges,
      fees: data[version].fees,
      isLoggedIn,
      text: data[version].text,
      version
    }}>
      {children}
    </ProviderContext.Provider>
  )
}
