
import { ReactNode } from 'react'

import { Button, Flex, Icons, Text } from 'react-mobi'
import { Default } from './Default'

export interface PrintableProps {
  children: ReactNode
  heading: ReactNode
}

export function Printable({children, heading}: PrintableProps) {
  function handlePrint() {
    window.print()
  }

  return (
    <Default heading={heading}>
      <Flex end full padding='5rem 3rem 0 0' row>
        <Button callback={handlePrint} icon={Icons.print} label='Print this Page' primary />
      </Flex>
      <Text legal>
        {children}
      </Text>
    </Default>
  )
}
