
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
} from 'react-mobi'

import { Printable } from '../layouts'
import { Title } from '../components'

export default function LendingPolicy() {
  const heading = (
    <Title>Policy on Responsible Lending</Title>
  )

  return (
    <Printable heading={heading}>
      <Flex gap='1rem' margin='5rem 3rem' start>
        <Text>
          As a responsible lender, we commit to you that this loan product abides by all applicable laws and that we strive for the utmost level of transparency and fairness:
        </Text>
        <Text>
          Not for all financial needs: This product is a line of credit. Each draw under your line of credit is intended to help with your immediate cash needs. Draws under this line of credit will not solve your long-term needs, and continuing draws will result in additional charges. We encourage you to (1) borrow only what you need and can repay, (2) reborrow on your line only after you have reduced the principal as much as possible, and (3) shop the marketplace until you are convinced that you have found the best possible solution. You may choose to consult a consumer credit counseling service for other options.
        </Text>
        <Text>
          Transparency of costs for your loan: The cost of credit will be clearly disclosed to you in terms of the dollar amount of the Cash Advance Fee and the periodic Fixed Finance Charge.<Text super>1</Text> For details on the specific costs of your line of credit, please see the <Link href='/terms-and-conditions'>Agreement and Terms and Conditions</Link>. We will never charge you any “hidden fees” that are not fully disclosed in the Agreement and Terms and Conditions.
        </Text>
        <Text>
          Right of Cancellation: If you change your mind about your line of credit, you may cancel it at any time prior to you making a draw. You also may repay any borrowed amounts at any time without incurring a prepayment penalty.
        </Text>
        <Text>
          Implications of Late and Non-Payment: If you don't make a payment on time we will attempt to contact you via one or more authorized methods, including phone calls, emails, and text messages to arrange for payment. All of our collections methods will be in accordance with the principles of the Fair Debt Collections Protection Act (FDCPA). In addition, we may re-present any returned payments to your bank in accordance with applicable law and regulatory requirements. If we are unable to reach you or we are unable to collect against your debt we may place or sell your debt with third party collection agencies. However, we only contract with collection agencies that agree to follow the FDCPA. Furthermore, since we may report loan payments to one or more credit bureaus, late or non-payment of your debt may impact your credit rating.
        </Text>
        <Text>
          Responsible Renewals and Refinancing Options: Because this is a line of credit, you may not refinance or renew it as you would a traditional payday loan. You can minimize the Cash Advance Fees<Text super>1</Text> you incur by making fewer draws on your line. When you make a draw on your line, you can eliminate the Fixed Finance Charge<Text super>1</Text> by paying your outstanding balance in full by the due date or minimize your Fixed Finance Charges<Text super>1</Text> by paying more than the minimum payment amount. We strongly encourage principal reduction and early payment to reduce your Fixed Finance Charges.<Text super>1</Text>
        </Text>
        <Text>
          Compliance with Laws: We are a tribal lending entity wholly owned by the Tunica-Biloxi Tribe of Louisiana, a federally recognized tribe located within the United States of America and operating within the Tribe's reservation. As such, we abide by applicable tribal lending laws and follow the principles of applicable federal lending laws where they don't conflict with tribal sovereignty. These laws include (but are not limited to):
        </Text>
        <List>
          <ListItem>Electronic Fund Transfer Act</ListItem>
          <ListItem>Equal Credit Opportunity Act</ListItem>
          <ListItem>Fair Credit Reporting Act</ListItem>
          <ListItem>Fair Debt Collection Practices Act</ListItem>
          <ListItem>Gramm-Leach-Bliley Act Privacy Requirements</ListItem>
          <ListItem>Truth in Lending Act</ListItem>
          <ListItem>Telemarketing Sales Rule</ListItem>
        </List>
        <Text>
          We Respect Your Privacy: We adhere to all applicable privacy laws. Please see <Link href='/privacy-policy'>Privacy Policy</Link> for details.
        </Text>
        <Text>
          Contacting Us: We always provide a physical address, a toll-free phone number, and an email address so that you can reach us in the most convenient manner for you. We strive to respond to your mail, calls, and email in as fast a manner as possible so we can quickly answer any questions and solve any problems you might have. Please see <Link href='/contact-us'>Contact Us</Link> for details.
        </Text>
      </Flex>
    </Printable>
  )
}
