
import {
  Flex,
  List,
  ListItem,
  Text,
} from 'react-mobi'

import { Email, Phone, Title, Website } from '../components'
import { Printable } from '../layouts'

export default function PrivacyPolicy() {
  const heading = (
    <Title>Privacy Policy</Title>
  )

  return (
    <Printable heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Text capitalize>
          MOBILOANS, LLC IS PROVIDING YOU THE FOLLOWING INFORMATION IN A MANNER CONSISTENT WITH PRINCIPLES UNDER UNITED STATES FEDERAL LAW TO THE EXTENT THAT THESE LAWS, RULES, AND PROCEDURES DO NOT CONTRADICT EITHER THE LAW OF THE TUNICA-BILOXI TRIBE OF LOUISIANA OR SOVEREIGN IMMUNITY OF THE TUNICA-BILOXI TRIBE OF LOUISIANA.
        </Text>
        <table className='styled'>
          <thead>
            <tr>
              <th>
                <Text bold capitalize>
                  FACTS
                </Text>
              </th>
              <th colSpan={2}>
                <Text bold capitalize>
                  WHAT DOES MOBILOANS, LLC DO WITH YOUR PERSONAL INFORMATION?
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Why?</td>
              <td colSpan={2}>
                Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.
              </td>
            </tr>
            <tr>
              <td>What?</td>
              <td colSpan={2}>
                The types of personal information we collect and share depend on the product or service you have with us. This information can include:
                <br />
                <br />
                <List>
                  <ListItem>Social Security number and income</ListItem>
                  <ListItem>Account balances and payment history</ListItem>
                  <ListItem>Credit history and credit scores</ListItem>
                </List>
              </td>
            </tr>
            <tr>
              <td>How?</td>
              <td colSpan={2}>
                All financial companies need to share customers' personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers' personal information; the reasons Mobiloans, LLC chooses to share; and whether you can limit this sharing.
              </td>
            </tr>
            <tr>
              <th>
                <Text bold>
                  Reasons we can share your personal information
                </Text>
              </th>
              <th>
                <Text bold>
                  Does MobiLoans, LLC share?
                </Text>
              </th>
              <th>
                <Text bold>
                  Can you limit this sharing?
                </Text>
              </th>
            </tr>
            <tr>
              <td>
                <Text bold>For our everyday business purposes</Text> - such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus
              </td>
              <td>YES</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                <Text bold>For our marketing purposes</Text> - to offer our products and services to you
              </td>
              <td>YES</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>
                <Text bold>For joint marketing</Text> with other financial companies
              </td>
              <td>NO</td>
              <td>WE DO NOT SHARE</td>
            </tr>
            <tr>
              <td>
                <Text bold>For our affiliates' everyday business purposes</Text> - information about your transactions and experiences
              </td>
              <td>NO</td>
              <td>WE DO NOT SHARE</td>
            </tr>
            <tr>
              <td>
                <Text bold>For our affiliates everyday business purposes</Text> - information about your creditworthiness
              </td>
              <td>NO</td>
              <td>WE DO NOT SHARE</td>
            </tr>
            <tr>
              <td>
                <Text bold>For our affiliates to market to you</Text>
              </td>
              <td>NO</td>
              <td>WE DO NOT SHARE</td>
            </tr>
            <tr>
              <td>
                <Text bold>For non-affiliates to market to you</Text>
              </td>
              <td>NO</td>
              <td>WE DO NOT SHARE</td>
            </tr>
            <tr>
              <td>
                <Text bold>To limit our sharing</Text>
              </td>
              <td colSpan={2}>
                <List>
                  <ListItem>
                    Call <Phone number='877-836-1518' /> - our menu will prompt you through your choice(s);
                  </ListItem>
                  <ListItem>
                    Log in to your account at <Website /> and click on the “Manage Profile” tab and select “Contact Preferences”; or
                  </ListItem>
                  <ListItem>
                    Contact us via email: <Email address='support@mobiloans.com' />.
                  </ListItem>
                </List>
                <br />
                <Text>
                  Please note:<br />
                  If you are a new customer, we can begin sharing your information as soon as the same day that you apply for a line of credit. When you are no longer our customer, we continue to share your information as described in this notice.<br />
                  <br />
                  However, you can contact us at any time to limit our sharing.
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text bold>Questions?</Text>
              </td>
              <td colSpan={2}>
                Call <Phone number='877-836-1518' />; go to <Website />; or email us at <Email address='support@mobiloans.com' />.
              </td>
            </tr>
          </tbody>
        </table>
        <table className='styled'>
          <tbody>
            <tr>
              <th colSpan={2}>
                <Text bold>
                  What we do
                </Text>
              </th>
            </tr>
            <tr>
              <td>
                <Text bold>How does MobiLoans, LLC protect my personal information?</Text>
              </td>
              <td>
                <Text>
                  To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.<br />
                  <br />
                  We also maintain physical, electronic and procedural safeguards (i.e., computer virus protection software, firewalls, 128 bit Secure Socket Layer). Only authorized persons have access.
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text bold>How does MobiLoans, LLC collect my personal information?</Text>
              </td>
              <td>
                <Text>
                  We collect your personal information, for example, when you
                </Text>
                <br />
                <br />
                <List>
                  <ListItem>Apply for a line of credit</ListItem>
                  <ListItem>Give us your income information</ListItem>
                  <ListItem>Provide employment information</ListItem>
                  <ListItem>Provide account information</ListItem>
                  <ListItem>Give us your contact information</ListItem>
                </List>
                <br />
                <Text>
                  We also collect your personal information from others, such as credit bureaus, affiliates, or other companies.
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                <Text bold>Why can't I limit all sharing?</Text>
              </td>
              <td>
                <Text>
                  Federal law gives you the right to limit only
                </Text>
                <br />
                <br />
                <List>
                  <ListItem>Sharing for affiliates' everyday business purposes-information about your creditworthiness</ListItem>
                  <ListItem>Affiliates from using your information to market to you</ListItem>
                  <ListItem>Sharing for non-affiliates to market to you.</ListItem>
                </List>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>
                <Text bold>
                  Definitions
                </Text>
              </th>
            </tr>
            <tr>
              <td>
                <Text bold>Affiliates</Text>
              </td>
              <td>
                <Text>
                  Financial and nonfinancial companies related by common ownership or control.
                </Text>
                <br />
                <br />
                <List>
                  <ListItem>
                    <Text italic>MobiLoans, LLC has no affiliates.</Text>
                  </ListItem>
                </List>
              </td>
            </tr>
            <tr>
              <td>
                <Text bold>Non-affiliates</Text>
              </td>
              <td>
                Financial and nonfinancial companies not related by common ownership or control.
              </td>
            </tr>
            <tr>
              <td>
                <Text bold>Joint Marketing</Text>
              </td>
              <td>
                A formal agreement between non-affiliated financial companies that together market financial products or services to you.
              </td>
            </tr>
          </tbody>
        </table>
        <table className='styled'>
          <thead>
            <tr>
              <th>
                <Text bold>Other Important Information</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='no-background'>
                <Text>
                  As part of our credit underwriting and information collection process, we will consider bank account information provided by you and we may detect additional bank accounts under your ownership. We also may collect and consider information about you from non-credit reporting agency data providers, including, for example, information about your location and the location of your mobile devices and publicly available information about you.<br />
                  <br />
                  <Text bold>Updating your information:</Text> If you need to update the personal information you have given us, log in to your account at <Website /> and click on the “Manage Profile” tab and select “Edit Your Application” or call <Phone number='877-836-1518' /> to speak to an account representative.<br />
                  <br />
                  <Text bold>“Do not track”:</Text> When someone visits the MobiLoans website, a cookie is placed on the visitor's computer (if the visitor accepts cookies). The cookie we place on your computer does not contain any of your personally identifiable information. MobiLoans may advertise its products and services on websites that you may visit, and MobiLoans may use service providers to place such advertisements. Those service providers (such as Google, Microsoft or Yahoo) may use cookies placed on your computer to show you advertisements for MobiLoans services on other websites. You can opt-out from service providers' and/or marketers' use of cookies in this manner by visiting each service provider's advertising opt-out page or by utilizing “do not track” functionality in your web browser. If you choose to not have your browser accept cookies from the MobiLoans website, you will not be able to subscribe to the service offerings on the MobiLoans website.<br />
                  <br />
                  <Text bold>COPPA (Children Online Privacy Protection Act):</Text> When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online. We do not specifically market to children under 13.<br />
                  <br />
                  <Text bold>Changes to this privacy policy:</Text> If we make material changes to this privacy policy, we will notify you via email and post the updated privacy policy on the MobiLoans website. Please take note of the “Effective Date” at the top of this privacy policy.
                </Text>
              </td>
            </tr>
          </tbody>
        </table>
      </Flex>
    </Printable>
  )
}
