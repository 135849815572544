
import {
  Flex,
  H2,
  List,
  ListItem,
  Text,
} from 'react-mobi'

import { ContractCell, Email, Phone, Title, Website } from '../components'
import { Printable } from '../layouts'
import SchumerBox, { SchumerAmounts, SchumerCharges, SchumerFees } from '../components/SchumerBox'

export default function TermsConditions() {
  const heading = (
    <Title>Mobiloans Terms and Conditions</Title>
  )

  return (
    <Printable heading={heading}>
      <Flex gap='1rem' margin='5rem 3rem' start>
        <Text>
          <ContractCell type='Title' />
        </Text>
        <Text bold capitalize>
          MOBILOANS, LLC IS AN ENTITY OWNED AND OPERATED BY THE TUNICA-BILOXI TRIBE OF LOUISIANA. THE CREDIT ISSUED TO YOU AND INFORMATION PROVIDED UNDER THIS AGREEMENT BY MOBILOANS IS DONE SO SOLELY UNDER THE PROVISIONS OF LAWS OF THE TUNICA-BILOXI TRIBE OF LOUISIANA AND CERTAIN FEDERAL LAWS AS SPECIFIED IN SECTION XIX BELOW.
        </Text>
        <Text>
          The law of your resident state may have interest rate limits and other consumer protection provisions that are more favorable. If you wish to have your resident state law apply, you should consider taking a loan from a licensed lender in your state.
        </Text>
        <Text>
          This Mobiloans Line of Credit Account Terms and Conditions (these <Text italic>“Terms and Conditions”</Text> or this <Text italic>“Agreement”</Text>) govern your Mobiloans Credit Account. In this Agreement, “you” and “your” refer to those persons who have applied for and been approved for Mobiloans Credit. <Text italic>“We”, “us”, “our” or “Lender”</Text> refer to MobiLoans, LLC, a tribal lending entity wholly owned by the Tunica-Biloxi Tribe of Louisiana, a sovereign nation located within the United States of America that is operating within the Tunica-Biloxi Reservation, and possessing the inherent characteristics and immunities of the Tribal government. <Text italic>“Tribe”</Text> or <Text italic>“Tribal”</Text> refers to the Tunica-Biloxi Tribe of Louisiana. Each advance made by us hereunder is being made from the Tunica-Biloxi Reservation. <Text bold>These Terms and Conditions contain an arbitration provision. Unless you act promptly to reject the arbitration provision, it will have a substantial effect on your rights in the event of a dispute.</Text>
        </Text>
        <Text>
          You should review these Terms and Conditions to fully understand how Mobiloans Credit works. If you have questions, you may contact Customer Support at <Phone number='877-836-1518' />. You should retain a copy of these Terms and Conditions for your records.
        </Text>
        <Text bold capitalize>
          <Text underline>HIGH COST CREDIT DISCLOSURE:</Text> MOBILOANS CREDIT IS AN EXPENSIVE FORM OF CREDIT. MOBILOANS CREDIT IS DESIGNED TO HELP CUSTOMERS MEET THEIR SHORT-TERM BORROWING NEEDS. THIS SERVICE IS NOT INTENDED TO PROVIDE A SOLUTION FOR LONGER-TERM CREDIT OR OTHER FINANCIAL NEEDS. ALTERNATIVE FORMS OF CREDIT MAY BE LESS EXPENSIVE AND MORE SUITABLE FOR YOUR FINANCIAL NEEDS.
        </Text>
        <Text>
          Please scroll down to read important information about rates, fees, and other cost information before submitting your application.
        </Text>
        <Text underline>
          Truth-in-Lending Disclosures
        </Text>
        <Text bold capitalize>
          IMPORTANT INFORMATION REGARDING RATES, FEES, AND OTHER COST INFORMATION
        </Text>
        <Flex full>
          <SchumerBox />
        </Flex>
        <Text>
          <Text bold underline>How we will calculate your balance:</Text> To calculate the total balance of your Account, at the beginning of each Billing Cycle we subtract all payments and credits made to your Account. Then we will add to your existing unpaid balance the applicable Fixed Finance Charge, the amount of any Mobiloans Cash draws made since your last Periodic Statement, and any applicable Cash Advance Fees for draws of Mobiloans Cash advanced during that Billing Cycle. Fixed Finance Charges are imposed based on the outstanding principal balance of Mobiloans Cash as of the end of the applicable Billing Cycle, excluding the amount of new Mobiloans Cash advances made during the Billing Cycle.
        </Text>
        <Text>
          <Text bold underline>Billing Rights:</Text> Information on your rights to dispute transactions and how to exercise those rights is provided in these Terms and Conditions.
        </Text>
        <Text>
          See <Text underline>Section VI</Text> for further details on costs and charges and <Text underline>Section XIV</Text> for further details on billing rights.
        </Text>
        <H2>I. Definitions</H2>
        <Text>
          <Text italic>“Account”</Text> and <Text italic>“Mobiloans Credit Account”</Text> mean your Mobiloans Credit relationship established under this Agreement. Your Mobiloans Credit Account is an open-end line of credit pursuant to which you may borrow, repay and re-borrow amounts from time to time, up to your Credit Limit, subject to your continuing eligibility and these Terms and Conditions.
        </Text>
        <Text>
          <Text italic>“Application”</Text> or <Text italic>“Mobiloans Credit Application”</Text> means each initial and supplemental credit application you submitted to Lender in connection with establishing your Mobiloans Credit Account with Lender.
        </Text>
        <Text>
          <Text italic>“AutoPay”</Text> means the automatic payment of the Minimum Payment Amount from your designated Demand Deposit Account.
        </Text>
        <Text>
          <Text italic>“Billing Cycle”</Text> means the interval between the days or dates of regular Periodic Statements. All Pay Frequencies have at least two Billing Cycles per month. Bi-weekly and weekly paid customers will have up to twenty-six (26) Billing Cycles per year.
        </Text>
        <Text>
          <Text italic>“Business Day”</Text> means any day a bank in Louisiana is open for business not including weekends or bank holidays.
        </Text>
        <Text>
          <Text italic>“Cash Advance Fee”</Text> means the nonrefundable fee charged for each draw on your Mobiloans Credit Account, regardless of the time the Mobiloans Cash you receive from that draw is outstanding.
        </Text>
        <Text>
          <Text italic>“Credit Limit”</Text> means the maximum amount you can borrow under your Mobiloans Credit Account. Your available credit is based on the outstanding principal balance of Mobiloans Cash and is not reduced by accrued Cash Advance Fees or Fixed Finance Charges.
        </Text>
        <Text>
          <Text italic>“Demand Deposit Account”</Text> means a bank checking account you have identified for distribution of Mobiloans Cash and/or collection of payments due pursuant to the terms of this Agreement.
        </Text>
        <Text>
          <Text italic>“Due Date”</Text> means the date reflected on your periodic statement on which your payment is due. All Pay Frequencies have at least two Billing Cycles per month, and therefore at least two Due Dates per month. Bi-weekly and weekly paid customers will have up to twenty-six (26) Billing Cycles per year, and therefore up to twenty-six (26) Due Dates per year.
        </Text>
        <Text>
          <Text italic>“Finance Charge”</Text> means Fixed Financed Charges and Cash Advance Fees.
        </Text>
        <Text>
          <Text italic>“Fixed Finance Charge”</Text> means a minimum charge, calculated on a tiered level, based upon the unpaid principal balance in your Mobiloans Credit Account and charged to your Account each Billing Cycle that you have an unpaid balance.
        </Text>
        <Text>
          <Text italic>“Minimum Payment Amount”</Text> means the minimum payment you agree to make each Billing Cycle, consisting of the Minimum Principal Amount and Cash Advance Fees and Fixed Finance Charges.
        </Text>
        <Text>
          <Text italic>“Minimum Principal Amount”</Text> means that portion of your Minimum Payment Amount that is applied to the outstanding principal balance of your Mobiloans Credit Account. The formula for calculating the Minimum Principal Amount is set forth in <Text underline>Section VII</Text> below.
        </Text>
        <Text>
          <Text italic>“Mobiloans Cash”</Text> means the amount of cash you receive from a draw under your Mobiloans Credit Account.
        </Text>
        <Text>
          <Text italic>“Mobiloans Credit”</Text> means the line of credit governed by this Agreement that allows eligible customers to receive short-term cash draws under this Agreement.
        </Text>
        <Text>
          <Text italic>“Pay Date”</Text> means the date that you submitted in your initial or updated Mobiloans Credit Application as the date on which you are paid wages or receive other sources of income or benefits.
        </Text>
        <Text>
          <Text italic>“Pay Frequency”</Text> means the frequency you receive your income payments which are either, weekly, bi-weekly, semi-monthly, or monthly. If your Pay Frequency is weekly, your Pay Dates are considered to be bi-weekly for determining your Billing Cycle under this Agreement. If your Pay Frequency is monthly, your Pay Dates are considered to be semi-monthly for determining your Billing Cycle under this Agreement. All Pay Frequencies have at least two Billing Cycles per month and are required to remit at least two Minimum Payment Amounts per month.
        </Text>
        <Text>
          <Text italic>“Periodic Statement”</Text> means the written statement issued for each Billing Cycle that describes, among other things, Mobiloans Cash transactions, accrued Cash Advance Fees and Fixed Finance Charges, payments made, other credits, balances that are past due, your previous balance, your new balance and your payment Due Date for that Billing Cycle.
        </Text>
        <Text>
          <Text italic>“Tribal Law”</Text> means any law or regulation duly enacted by the Tunica-Biloxi Tribe of Louisiana, a sovereign nation located within the United States of America.
        </Text>
        <H2>
          II. Eligibility for Mobiloans Credit
        </H2>
        <Text>
          You may be eligible for Mobiloans Credit if you meet certain eligibility criteria established by us, which may change from time to time at our sole discretion. As of the date of this Agreement, the eligibility criteria are as follows:
        </Text>
        <List>
          <ListItem>You have a regular source of income or benefits deposited to a personal qualified Demand Deposit Account;</ListItem>
          <ListItem>You are at least 18 years old (or at least 19 years old if you are a resident of Alabama or Nebraska);</ListItem>
          <ListItem>You <ContractCell type='Residency' /> (any change of address must be updated on your account within ten (10) days);</ListItem>
          <ListItem>You meet credit underwriting standards established by the Lender;</ListItem>
          <ListItem>You have identified a qualified Demand Deposit Account on the Application;</ListItem>
          <ListItem>You authorize the Lender to initiate automated transfers from your qualified Demand Deposit Account(s) to repay amounts owed under this Agreement or you enroll for payments by mail, as described below;</ListItem>
          <ListItem>Your qualified Demand Deposit Account(s) are not frozen or subject to legal process (such as a garnishment order); and</ListItem>
          <ListItem>You are not in default of this Agreement.</ListItem>
        </List>
        <H2>
          III. Establishing Your Mobiloans Credit Account
        </H2>
        <Text>
          Upon approval and verification by Lender of the information you submitted on your Application, your Mobiloans Credit Account will be established. Your Mobiloans Credit Account will be terminated if you do not request Mobiloans Cash for a period of twenty-four (24) consecutive months. By applying for and using your Mobiloans Credit, you acknowledge that you have received a copy of this Agreement and that you understand and accept its terms and conditions. Access to Mobiloans Cash is subject to the eligibility criteria provided in this Agreement and your compliance with the terms of this Agreement.
        </Text>
        <Text>
          You can obtain your current Credit Limit by: (a) logging in to your Mobiloans Credit Account online at <Website />; (b) calling Customer Support at <Phone number='877-836-1518' />; or (c) writing Mobiloans Customer Support at MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351.
        </Text>
        <H2>
          IV. Accessing Your Account and Receiving Mobiloans Cash
        </H2>
        <Text>
          Your Mobiloans Credit Account is an open-end line of credit pursuant to which you may borrow, repay and re-borrow amounts from time to time, up to your Credit Limit, subject to your continuing eligibility and these Terms and Conditions. You may request Mobiloans Cash online at <Website />. Aggregate Mobiloans Cash requests may not exceed your Credit Limit, and individual draws are limited to the availability under your Account. Proceeds deposited to a qualified Demand Deposit Account may be effectuated via Automate Clearing House (ACH) or mailed by check. If your request was to receive cash via ACH and was received before 4:00 p.m. Central Time on a Business Day, your requested Mobiloans Cash may be available on the next day your financial institution holding your Demand Deposit Account is open for business. Whether you receive your Mobiloans Cash by check or ACH, always check with your financial institution to accurately determine when the money will be available for use.
        </Text>
        <Text>
          You may cancel a request for Mobiloans Cash at no cost to you by calling Customer Support at <Phone number='877-836-1518' /> within five (5) days of requesting funds and, if you already received your Mobiloans Cash, you return the received Mobiloans Cash immediately as instructed by Customer Support. In the event that your draw of Mobiloans Cash will be funded by check and you desire to cancel your request, (a) if we have not mailed the check representing the Mobiloans Cash to you or (b) if you have not cashed the check representing the Mobiloans Cash, then we will cancel the check and your obligations with respect to that draw of Mobiloans Cash will be canceled. If you have cancelled funding <ContractCell type='3Times' /> times in the last <ContractCell type='12Mos' />, additional requests to cancel funds will require management approval and may be declined.
        </Text>
        <Text>
          <Text bold underline>Draw Restrictions:</Text> As noted above, aggregate Mobiloans Cash requests may not exceed your Credit Limit, and individual draws are limited to the availability under your Account. Additional draws may be restricted if you have not made one or more required Minimum Periodic Payments in full and on time, if we have reduced your Credit Limit as described in Section V or if your Account is not otherwise in good standing. If the outstanding principal amount of Mobiloans Cash at any time exceeds your Credit Limit for any reason, any such additional amounts will be subject to all provisions of this Agreement. Furthermore, any such excess amount outstanding will not increase your Credit Limit. In addition, if you have had an outstanding principal balance of Mobiloans Cash on your Account for at least thirty (30) consecutive months, then you may not obtain additional Mobiloans Cash until you pay down the balance of your Mobiloans Credit Account to zero dollars ($0). Once you have brought your Account balance to zero dollars ($0), your ability to draw Mobiloans Cash will be reinstated. See Section VIII below for further information on this pay down requirement and the reinstatement of your ability to draw Mobiloans Cash.
        </Text>
        <H2>
          V. Credit Limit
        </H2>
        <Text>
          Your Credit Limit is the maximum amount that you may borrow under your Mobiloans Credit Account. Your Credit Limit is assigned by the Lender and is between <ContractCell type='LineLowText' /> and <ContractCell type='LineHighText' />. This Credit Limit is subject to change with changes in your Demand Deposit Account status and/or underwriting eligibility. Your available credit is based on the outstanding principal balance of Mobiloans Cash and is not reduced by accrued Cash Advance Fees or Fixed Finance Charges. We reserve the right, at any time, and upon notice when required by Tribal Law or applicable federal law, to adjust your Credit Limit, including reducing it to zero dollars ($0), based on your use of Mobiloans Credit, your payment history with us, your moving to a state we do not service or we discontinue service in your state, and other factors, at the Lender's sole discretion. We also reserve the right to review your credit status at any time, including after obtaining credit reports and other credit information we believe to be relevant. Our review of such information may also result in our modifying your Credit Limit, including reducing your Credit Limit to zero dollars ($0), or closing your Account.
        </Text>
        <H2>
          VI. Costs and Charges
        </H2>
        <Text bold italic>
          Cash Advance Fee
        </Text>
        <Text>
          We charge a Cash Advance Fee for each draw on your Mobiloans Credit Account, regardless of the period of time for which the Mobiloans Cash you receive from that draw is outstanding. The Cash Advance Fee is a <Text bold capitalize>FINANCE CHARGE</Text>. The Cash Advance Fee is assessed each time a draw is requested, and there is no grace period within which you may repay an advance and avoid payment of the related Cash Advance Fee. The amount of the Cash Advance Fee that will be assessed on a draw of Mobiloans Cash is as follows:
        </Text>
        <Flex full>
          <SchumerFees />
        </Flex>
        <Text bold italic>
          Fixed Finance Charge
        </Text>
        <Text>
          A Fixed Finance Charge will be assessed at the beginning of each Billing Cycle based on the unpaid principal balance of Mobiloans Cash at the end of the prior Billing Cycle, excluding the amount of any new Mobiloans Cash advanced during that Billing Cycle, in accordance with the table below. The Fixed Finance Charge is a <Text bold capitalize>FINANCE CHARGE</Text>. If you pay the entire balance of a Mobiloans Cash draw in full by the applicable Due Date specified in the Periodic Statement for the Billing Cycle in which such draw was made, you will not be charged a Fixed Finance Charge on the amount of such Mobiloans Cash advance.
        </Text>
        <Flex full>
          <SchumerCharges />
        </Flex>
        <Text>
          <Text bold underline>How we will calculate your balance:</Text> To calculate the total balance of your Account, at the beginning of each Billing Cycle we subtract all payments and credits made to your Account. Then we will add to your existing unpaid balance the applicable Fixed Finance Charge, the amount of any Mobiloans Cash draws made since your last Periodic Statement, and any applicable Cash Advance Fees for draws of Mobiloans Cash advanced during that Billing Cycle. Fixed Finance Charges are imposed based on the outstanding principal balance of Mobiloans Cash as of the end of the applicable Billing Cycle, excluding the amount of new Mobiloans Cash advances made during the Billing Cycle.
        </Text>
        <Text>
          Making additional Mobiloans Cash draws when your account already has a principal balance will increase your outstanding principal balance. The minimum payment due will change and the repayments terms will be extended based on the new principal balance amount. Paying only the minimum payment due will mean that it will take longer to pay off your balance and will cost more in fees.
        </Text>
        <Text>
          <Text bold underline>Billing Rights:</Text> Information on your rights to dispute transactions and how to exercise those rights is provided in these Terms and Conditions. See <Text underline>Section XIV</Text> for further details on billing disputes.
        </Text>
        <Text bold>
          VII. Your Promise to Pay
        </Text>
        <Text>
          You promise to pay the total of all outstanding Mobiloans Cash along with all accrued Cash Advance Fees and Fixed Finance Charges as described in this Agreement.
        </Text>
        <Text>
          You agree to make the Minimum Payment Amount shown on each Periodic Statement on or before the applicable Due Date for such payment. <ContractCell type='GracePeriod' /> The Minimum Payment Amount consists of the Minimum Principal Payment and accrued Cash Advance Fees and Fixed Finance Charges as described in this Agreement. Payments are credited to your Account effective as of the day they are received.
        </Text>
        <Text>
          You may experience a delay between the date of your payment and the time you are able to take additional Mobiloans Cash while we verify that sufficient funds are available and/or we receive credit for any payments drawn on another depository institution. This action will not result in more cost to you, but it may delay the availability and/or amount of your future Mobiloans Cash.
        </Text>
        <Text>
          If you fail to pay the Minimum Payment Amount on or before the applicable Due Date, an AutoPay payment will automatically be deducted by us from your Demand Deposit Account (see <Text underline>Section VIII</Text> below) if you have authorized the AutoPay feature.
        </Text>
        <Text>
          To minimize the amount of Finance Charges you may incur, you are encouraged to pay your Mobiloans Cash balances in full on or before the applicable Due Date. <Text bold>You also may make payments toward your unpaid balance at any time without penalty.</Text>
        </Text>
        <Text>
          Your payment options are discussed more fully in <Text underline>Section VIII</Text> below.
        </Text>
        <Text bold italic>
          Calculation of Minimum Principal Amount
        </Text>
        <Text>
          The Minimum Principal Amount will be calculated using a percentage of the outstanding Mobiloans Cash statement balance immediately after the most recent draw, or a Minimum Principal Amount of sixteen dollars ($16), whichever is greater. See chart below for the applicable percentages to calculate the Minimum Principal Amount.
        </Text>
        <Flex full>
          <SchumerAmounts />
        </Flex>
        <H2>
          VIII. Payment Options
        </H2>
        <Text>
          <Text bold italic>Payments in Full and Partial Payments:</Text> You may pay your outstanding balance in full at any time. We also accept partial payments as further described in this <Text underline>Section VIII</Text>.
        </Text>
        <Text>
          <Text bold italic>AutoPay:</Text> AutoPay is the automatic payment of the Minimum Payment Amount specified in the applicable Periodic Statement. If you elect to make AutoPay payments via ACH debit, we process an ACH payment from your Demand Deposit Account for the amount of each AutoPay payment so that such payment is received on the applicable Due Date. You do not need to agree to AutoPay as a condition to opening or using your Mobiloans Credit Account. If you have enrolled in the AutoPay feature, the payment of the Minimum Payment Amount will be made from your Demand Deposit Account unless you have otherwise paid the Minimum Payment Amount by other means by 4:00 p.m. Central Time on the Business Day prior to the applicable Due Date. If you are an AutoPay customer and you remit a payment, whether by mail or electronically, that is received and processed before the AutoPay processing date and that results in the payment in full of the Minimum Payment Amount set forth in the applicable Periodic Statement, then we will not process an AutoPay payment for that Periodic Statement. See <Text underline>Section XXI</Text> for more details.
        </Text>
        <Text>
          <Text bold italic>Borrower-Scheduled Electronic Payments:</Text> You may schedule an electronic payment at any time up to the full amount of the outstanding balance of your Mobiloans Credit Account prior to the applicable Due Date. You may schedule these payments online at <Website />, by calling Customer Support at <Phone number='877-836-1518' /> or by mail. In order for an electronic payment to be received on your payment Due Date, we must receive your scheduled payment request by 4:00 p.m. Central Time on the Business Day prior to the applicable Due Date. Depending on the amount and timing of the early or partial payment, such payments may not reduce the Cash Advance Fees or Fixed Finance Charges that may accrue. See <Text underline>Section VI</Text> and <Text underline>Section VII</Text> above.
        </Text>
        <Text>
          <Text bold italic>Borrower-Scheduled Debit Payments:</Text> Mobiloans may work with a third-party to enable you to schedule a payment using a debit card to pay up to the full amount of the outstanding balance (or any portion thereof) of your Mobiloans Credit Account at any time up to 4:00 p.m. Central Time on the Business Day prior to the applicable Due Date. If applicable, you may schedule these payments by calling Customer Support at <Phone number='877-836-1518' />. If you provide a debit card number to Mobiloans, such debit card will not be used for any payment unless you schedule a payment. Please note that Mobiloans relies on third parties to provide this service and therefore reserves the right to terminate or suspend this service at any time. If you elect to pay your Mobiloans Credit Account via AutoPay or by mail, you can make an additional payment at any time using your debit card; however, providing a debit card to your Mobiloans Credit Account will not remove you from AutoPay or otherwise change your payment obligations. In order for a debit card payment to be received on your payment Due Date, we must receive your scheduled payment request by 4:00 p.m. Central Time on the Business Day prior to the applicable Due Date. Depending on the amount and timing of the early or partial payment, such payments may not reduce the Cash Advance Fees or Fixed Finance Charges that may accrue.
        </Text>
        <Text>
          <Text bold italic>Payments by Mail:</Text> Extension of Mobiloans Credit is not conditioned on your repayment by electronic means of the amounts you owe. You may make payments by mail, using personal check, certified check or money order payable to “MobiLoans, LLC”, and any such payment must be received at our payment address, P.O. Box 42917, Philadelphia, PA 19101, by 5:00 p.m. Eastern Time on the applicable payment Due Date. To send your check via overnight mail or courier service, please send to MobiLoans, LLC c/o MetaSource, 1900 Frost Road, Suite 100, Bristol, Pennsylvania 19007 in time for Mobiloans to receive your payment prior to 5:00 p.m. Eastern Time on your Due Date. If you are an AutoPay customer and you remit a payment by mail, your AutoPay payment for the current Billing Cycle will still process unless your mailed payment is received and processed by 4:00 p.m. Central Time on the Business Day prior to the applicable Due Date and results in the payment in full of the Minimum Payment Amount set forth in the applicable Periodic Statement. If you remit payment by mail and such payment is received on a non-Business Day, your payment will be posted to your account on the day it is received.
        </Text>
        <Text>
          <Text bold italic>Check Conversion Notification:</Text> When you provide a personal check as payment, you agree we can either use information from your check to make a one-time electronic withdrawal from your Bank Account or to process the payment as a check transaction. When we use information from your personal check to make a withdrawal from your Bank Account, funds may be withdrawn from your Bank Account as soon as the same day we receive your payment and you will not receive your check back from your financial institution. For questions, please call Customer Support at <Phone number='877-836-1518' />.
        </Text>
        <Text>
            <Text bold italic>Required Paydown to Zero Dollars ($0) after Thirty (30) Months with an Outstanding Principal Balance of Mobiloans Cash:</Text> As described in <Text underline>Section IV</Text>, if you have had an outstanding principal balance of Mobiloans Cash on your Mobiloans Credit Account for at least thirty (30) consecutive months, then your ability to initiate additional draws of Mobiloans Cash will be restricted until such time as you pay down the balance of your Mobiloans Credit Account to zero dollars ($0). Repayment of your outstanding balance to zero dollars ($0) can be made by paying the Minimum Payment Amount over the maximum term for the outstanding principal balance. <ContractCell type='PrincipalBalances' /> Using your line of credit multiple times before it is paid down to $0 may mean that you maintain an outstanding balance for more than thirty (30) consecutive months. You can pay off the outstanding balance in full, or over a shorter time frame, by scheduling additional electronic payments, sending additional payments by mail, paying more than the Minimum Payment Amount or just remitting a payment in full. Once you have paid in full the outstanding balance of your Account, your ability to make draws of Mobiloans Cash up to your Credit Limit will be reinstated. Depending on the method by which you make the final payment that brings your Account balance to zero dollars ($0), it may take up to seven (7) Business Days to process the payment and reinstate your ability to make draws of Mobiloans Cash.
        </Text>
        <Text>
          <Text bold italic>Payment Extensions:</Text> You may extend or “push” a payment to a later date as is described herein. The maximum payment date that a payment may be extended will be the next scheduled payment date. If you choose to extend the payment to the maximum payment date, you will have two payments processed on that date (the extended payment and the current payment due). You may not extend two payments in a row. A payment that has been extended cannot be delayed a second time if the maximum number of days allowed was granted the first time.
        </Text>
        <Text>
          Your ability to utilize this payment extension option is limited to a maximum of ninety-nine (99) times throughout the life of your account.
        </Text>
        <H2>
          IX. Application of Payments
        </H2>
        <Text>
          All payments received (whether electronically or otherwise) will be applied first to any accrued and unpaid Cash Advance Fees and Fixed Finance Charges, and then to the outstanding principal balance of your Mobiloans Credit Account.
        </Text>
        <H2>
          X. Balance Computation Method
        </H2>
        <Text>
          We calculate your balance as shown under “How we will calculate your balance” above.
        </Text>
        <H2>
          XI. Servicing Your Account
        </H2>
        <Text>
          In connection with the servicing of your Mobiloans Credit Account, you hereby authorize us to contact you, including through the use of an autodialer, text messaging (if you have opted-in to text messaging in your Application), or prerecorded message, at any phone number you have provided to us in your Application or otherwise, including mobile phone numbers, and at any address and email address we have for you in our records or from other public and nonpublic databases we may lawfully access. Where allowed by law, we also may contact other individuals who may be able to provide updated employment, location, and contact information for you.
        </Text>
        <H2>
          XII. Default
        </H2>
        <Text>
          <Text underline>Events of Default:</Text> We may declare you to be in default of this Agreement at any time if: (a) you fail to comply with the terms of this Agreement, including your repayment obligations with respect to borrowings under your Mobiloans Credit Account; (b) we discover that any information you have provided to us is false or misleading in any material respect; (c) you have exceeded the limitations regarding the usage of your Mobiloans Credit Account as set forth in this Agreement; or (d) you have not provided us with information we may request from time to time to satisfy our obligations to comply with the Bank Secrecy Act or other statutes or regulations that apply to us.
        </Text>
        <Text>
          <Text underline>Consequences of Default:</Text> If an event of default has occurred, we may, at our option, do any one or more of the following things: (a) require you to immediately pay us everything you owe us on your Mobiloans Credit Account, including, without limitation, all outstanding Mobiloans Cash and accrued Cash Advance Fees and Fixed Finance Charges; (b) withdraw money from your Demand Deposit Account that was not available when we tried to withdraw it at an earlier time, if you have authorized electronic payments; (c) we may suspend or terminate your right to access your Mobiloans Credit Account and to receive draws of Mobiloans Cash; and (d) pursue all legally available means under Tribal Law and applicable federal law to collect what you owe us. Except as expressly prohibited by Tribal Law and applicable federal law, by agreeing to these Terms and Conditions you waive notice of default, dishonor, demand for payment, protest, presentment, and any other notices. In the event we declare all amounts owed on your Mobiloans Credit Account immediately due because you did not pay us, then, if you have authorized electronic payments, you further authorize us and our agents and representatives to withdraw money from your Demand Deposit Account in the full amount due. By choosing to exercise any one or more of these remedies, we do not give up our right to use another way to collect the money you owe us later. We may decide not to use any of the ways described above to be paid back the money that you owe us. If so, we do not give up our right to consider what you said you would do to make payments and, if you fail to make those payment(s), we will consider you to be in default.
        </Text>
        <H2>
          XIII. Periodic Statements
        </H2>
        <Text>
          Not less than once each Billing Cycle during which there is (i) <ContractCell type='One' /> or more Mobiloans Cash transactions, (ii) a payment is received, or (iii) an outstanding balance owing on your Mobiloans Credit Account, we will make available to you electronically, or if you specifically request, by mail, a Periodic Statement reflecting, among other things, Mobiloans Cash transactions, accrued Cash Advance Fees and Fixed Finance Charges, payments made, other credits, balances that are past due, your previous balance, and your new balance. In addition, we will provide you from time to time with any other disclosures or information required by this Agreement, Tribal Law, and applicable federal law.
        </Text>
        <Text>
          Your Periodic Statements will be generated fourteen (14) days prior to the applicable Due Date for such Billing Cycle. A notification will be emailed to the email account you have on file in your Account, and your statement will be available electronically at <Website />. You may choose not to receive your statements electronically. If you choose to receive paper statements, please notify us by writing to Mobiloans Customer Support at MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351.
        </Text>
        <H2>
          XIV. Billing Rights
        </H2>
        <Text>
          <Text bold capitalize>YOUR BILLING RIGHTS - KEEP THIS NOTICE FOR FUTURE USE</Text>
        </Text>
        <Text>
          This notice contains important information about your rights and our responsibilities under Tribal Law and applicable federal law.
        </Text>
        <Text bold italic>
          What To Do If You Find a Mistake on Your Periodic Statement
        </Text>
        <Text>
          If you think there is an error on your Periodic Statement, write to Mobiloans Customer Support at MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351, contact us electronically at <Website />, or call us at <Phone number='877-836-1518' />.
        </Text>
        <Text>
          In your correspondence, give us the following information:
        </Text>
        <List>
          <ListItem>Account information - your name and Account number;</ListItem>
          <ListItem>Dollar amount - the dollar amount of the suspected error; and</ListItem>
          <ListItem>Description of problem - if you think there is an error on your Periodic Statement, describe what you believe is wrong and why you believe it is a mistake.</ListItem>
        </List>
        <Text>
          You must contact us:
        </Text>
        <List>
          <ListItem>Within sixty (60) days after the error first appeared on your Periodic Statement; and</ListItem>
          <ListItem>By 4:00 p.m. Central Time at least two (2) Business Days before an automated payment is scheduled if you want to stop payment on the amount you think is wrong.</ListItem>
        </List>
        <Text>
          You must notify us of any potential errors in writing or electronically. You may call us, but if you do not send in the required written information, we are not required to investigate any potential errors and you may have to pay the amount in question.
        </Text>
        <Text bold italic>
          What Will Happen After We Receive Your Correspondence
        </Text>
        <Text>When we receive your correspondence, we must do two things:</Text>
        <List ordered>
          <ListItem>Within thirty (30) days of receiving your correspondence, we must tell you that we received your correspondence. We will also tell you if we have already corrected the error.</ListItem>
          <ListItem>Within ninety (90) days of receiving your correspondence, we must either correct the error or explain to you why we believe the Periodic Statement is correct.</ListItem>
        </List>
        <Text>
          While we investigate whether or not there has been an error:
        </Text>
        <List>
          <ListItem>We cannot try to collect the amount in question<ContractCell type='Comma' /> or report you as delinquent on that amount.</ListItem>
          <ListItem>The charge in question may remain on your Periodic Statement, and we may continue to charge Finance Charges on that amount in accordance with this Agreement.</ListItem>
          <ListItem>While you do not have to pay the amount in question, you are responsible for the remainder of the outstanding balance of your Account.</ListItem>
          <ListItem>We can apply any unpaid amount against your Credit Limit.</ListItem>
        </List>
        <Text>
          After we finish our investigation, one of two things will happen:
        </Text>
        <List ordered>
          <ListItem><Text italic>If we made a mistake:</Text> You will not have to pay the amount in question or any interest or other fees related to that amount.</ListItem>
          <ListItem><Text italic>If we do not believe there was a mistake:</Text> You will have to pay the amount in question, along with applicable Finance Charges on that amount in accordance with this Agreement. We will send you a statement of the amount you owe and the date payment is due. We may then report you as delinquent if you do not pay the amount we think you owe.</ListItem>
        </List>
        <Text>
          If you receive our explanation but still believe your Periodic Statement is wrong, you must write to us within ten (10) days telling us that you still refuse to pay. If you do so, we cannot report you as delinquent without also reporting that you are questioning your Periodic Statement. We must tell you the name of anyone to whom we reported you as delinquent, and we must let those organizations know when the matter has been settled between us.
        </Text>
        <Text>
          If we do not follow all of the rules above, you do not have to pay the first fifty dollars ($50) of the amount you question even if your Periodic Statement is correct.
        </Text>
        <H2>
          XV. Change in Terms
        </H2>
        <Text>
          We reserve the right to change the terms of this Agreement at any time with notice to you as required by Tribal Law and applicable federal law. Such changes may apply to Mobiloans Credit and any current amounts of outstanding Mobiloans Cash as well as to future Mobiloans Cash balances. By continuing to use the Mobiloans Credit service, you are accepting the change in terms. You may contact Mobiloans Customer Support at any time and request that your Mobiloans Credit Account be closed. If you close your account, you will still be required to repay all amounts that you owe on your Mobiloans Credit Account pursuant to the continuing terms of the Agreement.
        </Text>
        <H2>
          XVI. Mobiloans Rewards Program
        </H2>
        <Text>
          As a MobiLoans, LLC customer, you are automatically enrolled in the MobiLoans, LLC Rewards Program (“Mobiloans Rewards”). Mobiloans Rewards terms and conditions may be found at <Website route='/rewards' /> and are hereby incorporated into these Terms and Conditions as if fully set out herein.
        </Text>
        <H2>
          XVII. Transfer of Rights; Maintenance of Register
        </H2>
        <Text>
          We may assign or transfer this Agreement, or any of our rights hereunder, to another person or entity without notice or consent from you. Regardless of any transfer, this Agreement shall remain exclusively subject to Tribal Law and applicable federal law. MobiLoans, LLC, (the "Registrar") acting solely for this purpose as your irrevocably appointed agent, shall maintain at an office located within the geographic boundaries of the United States a copy of each assignment of this Agreement delivered to it and a register (the "Register") for the recordation of the names and addresses of the original owner and assignees, and the amounts of the principal, interest, fees, charges and other amounts owing to each from time to time pursuant to the terms of this Agreement. The Register may be in electronic form. The entries of the Register shall be conclusive, and you, the Registrar, the Lender and all of its assignees shall treat each person whose name is recorded in the Register pursuant to these terms as the owner of such principal, interest, fees, charges and other amounts for all purposes of this Agreement and any rights hereunder, notwithstanding notice to the contrary. The name of the owner in the Register shall be available to you by written request to the Registrar at any reasonable time and from time to time upon reasonable prior notice. In addition to the foregoing, the Registrar shall include on the Register the names and addresses of those persons holding participation interests in the receivables outstanding from time to time in the Accounts of which it has notice. Any fees and expenses of the Registrar for its services shall be charged to the registered owner of the Account and not to you.
        </Text>
        <H2>
          XVIII. Dispute Resolution; Arbitration
        </H2>
        <Text bold>
          <Text underline>Notice of Waiver of Jury Trial and Arbitration Agreement</Text>
        </Text>
        <Text>
          This Agreement includes a binding Waiver of Jury Trial and Arbitration Agreement. You may opt out of the Waiver of Jury Trial and Arbitration Agreement by following the instructions below.
        </Text>
        <Text>
          <Text upper>RIGHT TO OPT OUT. IF YOU DO NOT WISH YOUR ACCOUNT TO BE SUBJECT TO THE FOLLOWING WAIVER OF JURY TRIAL AND ARBITRATION AGREEMENT, YOU MUST ADVISE US IN WRITING AT MOBILOANS, LLC, P.O. BOX 1409, MARKSVILLE, LA 71351 OR VIA EMAIL AT <Email address='support@mobiloans' />. YOU MUST CLEARLY PRINT OR TYPE YOUR NAME AND ACCOUNT NUMBER OR SOCIAL SECURITY NUMBER AND STATE THAT YOU REJECT ARBITRATION. YOU MUST GIVE WRITTEN NOTICE; IT IS NOT SUFFICIENT TO TELEPHONE US. WE MUST RECEIVE YOUR LETTER OR E-MAIL WITHIN SIXTY (60) DAYS AFTER THE DATE YOUR MOBILOANS CREDIT ACCOUNT IS ESTABLISHED OR YOUR REJECTION OF ARBITRATION WILL NOT BE EFFECTIVE. IN THE EVENT YOU OPT OUT OF THE ARBITRATION AGREEMENT, ANY DISPUTES UNDER THIS AGREEMENT OR RELATED TO YOUR MOBILOANS CREDIT ACCOUNT SHALL NONETHELESS BE GOVERNED UNDER THE LAWS OF THE TUNICA-BILOXI TRIBE OF LOUISIANA AND APPLICABLE FEDERAL LAW AND MUST BE BROUGHT WITHIN THE TRIBAL COURT SYSTEM, TO WHOSE JURISDICTION YOU IRREVOCABLY CONSENT FOR THE PURPOSES OF THIS AGREEMENT.</Text>
        </Text>
        <Text>
          WAIVER OF JURY TRIAL AND ARBITRATION AGREEMENT
        </Text>
        <Text>
          In this Waiver of Jury Trial and Arbitration Agreement (this “Arbitration Agreement”), “Tribe” or “Tribal” refers to the Tunica-Biloxi Tribe of Louisiana, a sovereign nation located within the United States of America, and “Tribal Law” means any law or regulation duly enacted by the Tunica-Biloxi Tribe of Louisiana.
        </Text>
        <Text>
          PLEASE READ THIS WAIVER OF JURY TRIAL AND ARBITRATION AGREEMENT CAREFULLY. Unless you exercise your right to opt-out of arbitration in the manner described above, any dispute related to this Agreement will be resolved by binding arbitration. Arbitration replaces the right to go to court, including the right to have a jury, to engage in discovery (except as may be provided in the arbitration rules), and to participate in a class action or similar proceeding. In arbitration, a dispute is resolved by an arbitrator instead of a judge or jury. Arbitration procedures are simpler and more limited than court procedures. Any arbitration will be limited to addressing your dispute individually and will not be part of a class-wide or consolidated arbitration proceeding.
        </Text>
        <Text>
          <ContractCell type='ArbAgreement' />
        </Text>
        <Text>
          <ContractCell type='ArbDefined' />
        </Text>
        <Text>
          You acknowledge and agree that by entering into this Arbitration Agreement:
        </Text>
        <List>
          <ListItem><Text bold capitalize>YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY TO RESOLVE ANY DISPUTE ALLEGED AGAINST US OR RELATED THIRD PARTIES;</Text></ListItem>
          <ListItem><Text bold capitalize>YOU ARE GIVING UP YOUR RIGHT TO HAVE A COURT RESOLVE ANY DISPUTE ALLEGED AGAINST US OR ANY HOLDER; and</Text></ListItem>
          <ListItem><Text bold capitalize>YOU ARE GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, AND/OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT FILED AGAINST US AND/OR RELATED THIRD PARTIES. <ContractCell type='ArbClass' /></Text></ListItem>
        </List>
        <Text>
          <ContractCell type='ArbInitiation' />
        </Text>
        <Text>
          <ContractCell type='Arbitration' />
        </Text>
        <Text>
          <ContractCell type='ArbCost' />
        </Text>
        <Text>
          Waiver of Jury Trial and Waiver of Ability to Participate in a Class Action. YOU HEREBY AGREE THAT YOU ARE WAIVING YOUR RIGHT TO A JURY TRIAL, TO HAVE A COURT DECIDE YOUR DISPUTE, AND YOU ARE WAIVING YOUR ABILITY TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, TO PARTICIPATE IN A CLASS ACTION LAWSUIT, OR IN ANY OTHER REPRESENTATIVE CAPACITY FOR OTHERS IN THE ARBITRATION, AND TO CERTAIN DISCOVERY AND OTHER PROCEDURES THAT WOULD BE AVAILABLE IN A LAWSUIT. The arbitrator has the ability to award all remedies available under Tribal Law and applicable federal law, whether at law or in equity, to the prevailing party, except that the parties agree that the arbitrator has no authority to conduct class-wide proceedings and will be restricted to resolving the individual Disputes between the parties. The validity, effect, and enforceability of this waiver of class action lawsuit and class-wide arbitration is to be determined by the arbitrator. If the arbitrator fails or refuses to enforce the waiver of class-wide arbitration, the parties agree that the Dispute will proceed in Tribal court and will be decided by a Tribal court judge, sitting without a jury, under applicable court rules and procedures and may be enforced by such court through any measures or reciprocity provisions available. As an integral component of accepting this Agreement, you consent to the jurisdiction of the Tribal courts for purposes of this Agreement.
        </Text>
        <Text>
          <ContractCell type='ArbReview' />
        </Text>
        <Text>
          Other Provisions. This Arbitration Agreement will survive: (i) termination or changes in this Agreement, the Account, or the relationship between us concerning the Account; (ii) the bankruptcy of any party; and (iii) any transfer, sale or assignment of my Account, or any amounts owed on my account, to any other person or entity. This Arbitration Agreement benefits and is binding upon you, your respective heirs, successors and assigns. It also benefits and is binding upon us, our successors and assigns, and related third parties. The Arbitration Agreement continues in full force and effect, even if your obligations have been paid or discharged through bankruptcy. The Arbitration Agreement survives any termination, amendment, expiration, or performance of any transaction between you and us and continues in full force and effect unless you and we otherwise agree in writing. If any of this Arbitration Agreement is held invalid, the remainder shall remain in effect.
        </Text>
        <H2>
          XIX. Governing Law
        </H2>
        <Text>
          This Agreement and the Arbitration Agreement are governed by the laws of the Tunica-Biloxi Tribe of Louisiana, the Indian Commerce Clause of the United States Constitution, the Federal Arbitration Act (“FAA”), and any applicable federal law necessary to uphold federal substantive statutory rights or remedies.
        </Text>
        <H2>
          XX. Electronic Signature and Electronic Records
        </H2>
        <Text>
          We are required by law to provide you with initial disclosures, Periodic Statements and certain other disclosures and notices (collectively, “Disclosures”). <Text bold>By checking the “Sign Here” box on the Application, that action will signify your agreement that this Agreement and the Disclosures we provide to you constitute electronic records under the Electronic Signatures in Global and National Commerce Act (15 U.S.C. Sections 7001 and following) in a manner consistent with Tribal Law and applicable federal law.</Text>
        </Text>
        <Text>
          By checking the <Text italic>“Sign Here”</Text> box on your Application, you agree to receive all such disclosures electronically. To access these Disclosures, in most cases, we will provide you with such disclosures at our web site or the web sites of our vendors. By checking the box, you acknowledge that you are able to electronically access the Mobiloans website (<Website />), and to electronically access and print the periodic statements and other Disclosures we will be providing to you in connection with your Mobiloans Credit Account. We will notify you of Disclosures by email and will provide copies of any Disclosures to you in electronic form by including them in the email, posting them on the Mobiloans website or by providing a link to them on the website. In order to access, view and retain Disclosures in electronic form, you must have a computer with Internet access. The minimum system requirements include software that supports 128-bit security encryption and Adobe Reader® version 9.0.
        </Text>
        <Text>
          Your agreement to receive disclosures and notices from us in electronic form does not mean you cannot obtain a paper copy of any such disclosure or notice. If you wish to obtain a paper copy of any document or withdraw your consent to receive Disclosures electronically, contact us in writing at Mobiloans Customer Support, MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351. You will be charged a five-dollar ($5.00) fee for the provision of each paper copy. The same fee will apply to customers who withdraw their consent to receive electronic disclosures and notices.
        </Text>
        <Text>
          By checking the “Sign Here” box for Electronic Delivery of Disclosures on your Application, you acknowledge receipt of the Terms and Conditions governing your Mobiloans Credit Account, that you have read and agreed to these Terms and Conditions, and that you agree to receive Disclosures from us in electronic form.
        </Text>
        <H2>
          XXI. Electronic Payment Authorization
        </H2>
        <Text>
          Mobiloans gives you the option to receive your Mobiloans Cash electronically and to repay amounts owing on your Mobiloans Credit Account electronically. If you choose to receive your Mobiloans Cash electronically and agree to repay electronically, then the following provisions apply.
        </Text>
        <Text bold italic>
          Consent to Pre-Authorized Electronic Payments
        </Text>
        <Text>
          By choosing to receive your Mobiloans Cash electronically and agreeing to repay electronically selecting the “Electronic” option on the Select Cash Amount page, you agree to this Electronic Payment Authorization and authorize and request MobiLoans, LLC to initiate ACH debits to your Demand Deposit Account for your regularly scheduled AutoPay payments equal to the Minimum Payment Amount disclosed on each Periodic Statement in time for such payments to be received by MobiLoans, LLC on the payment Due Date set forth in each such Periodic Statement. For each payment, whenever an ACH debit entry to your Demand Deposit Account is returned to us for any reason, your next scheduled payment amount may change based on the returned payment and any fee changes as a result of a returned payment. We may reinitiate an ACH debit entry to your Demand Deposit Account up to two (2) additional times after our first presentation for each scheduled payment amount. If your payment is due on a non-business day, it will be processed on the next business day. This Electronic Payment Authorization is to remain in full force and effect until MobiLoans, LLC shall have received notification from you stating that you terminate this authorization in such time and in such manner as to afford MobiLoans, LLC a reasonable opportunity to act upon such notice.
        </Text>
        <Text>
          This Electronic Payment Authorization applies to the repayment of all amounts owing on your Mobiloans Credit Account, including amounts outstanding on or before the date on which you agreed to this Electronic Payment Authorization. If your Mobiloans Credit Account is in Default you agree we may initiate an ACH debit to your Demand Deposit Account for the entire amount you owe us (see Section XII, Default, above). This Electronic Payment Authorization is to remain in full force and effect for as long as your Mobiloans Credit Account is open. You may revoke this Electronic Payment Authorization with notice before <ContractCell type='4PM' /> Central Time the business day prior to your next scheduled payment due date, by calling us at <Phone number='877-836-1518' /> or writing to us at Mobiloans Customer Support, MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351. If you revoke this Electronic Payment Authorization, you agree to make payments to us by another method acceptable to us as set forth in these Terms and Conditions. In no event shall any revocation of this Electronic Payment Authorization be effective with respect to payment entries processed by us prior to our receipt of a notice to withdraw such Electronic Payment Authorization.
        </Text>
        <Text>
          Your bank may impose a fee if your Demand Deposit Account becomes overdrawn or if a payment is attempted against your Demand Deposit Account that would cause it to become overdrawn. You will not hold us or our agents, representatives, successors or assigns responsible for any fees you must pay as a result of payment being presented at your bank in connection with this Electronic Payment Authorization. Contact your bank for more information specific to your Demand Deposit Account.
        </Text>
        <Text>
          You authorize us to verify and correct all information you have provided to us about your Demand Deposit Account, including, without limitation, past and/or current information about your bank, your bank routing and transit number and your Demand Deposit Account number.
        </Text>
        <Text>
          This Electronic Payment Authorization is subject to the following provisions:
        </Text>
        <List>
          <ListItem><Text italic>Right to stop payment and procedure for doing so.</Text> If you have told us in advance to make payments out of your Demand Deposit Account, you can stop any of these payments by calling us at <Phone number='877-836-1518' /> Monday-Friday 7:00 am - 7:00 pm Central Time and Saturday 7:00 am - 3:00 pm Central Time or writing to us at Mobiloans Customer Support, MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351, in time for us to receive your request by 4:00 p.m. Central Time at least one (1) Business Day before the payment is scheduled to be made. If you call, we may also require you to put your request in writing and get it to us within fourteen (14) days after your call.</ListItem>
          <ListItem><Text italic>Liability for failure to stop payment of preauthorized transfer.</Text> If you order us to stop a pre-authorized transfer by 4:00 p.m. Central Time at least one (1) Business Day before the transfer is scheduled, and we do not do so, we will be liable for your losses or damages.</ListItem>
          <ListItem><Text italic>Notice of varying amounts.</Text> You agree that you will receive a notice at least ten (10) days before a payment is debited from your Demand Deposit Account in an amount that varies from the amount disclosed (a) in your periodic billing statement or (b) in your payment schedule on the My Account page.</ListItem>
        </List>
        <Text>
          This Electronic Payment Authorization is a payment mechanism only and does not give us collection rights greater than those otherwise contained in your Mobiloans Credit Account terms and conditions. This Electronic Payment Authorization does not constitute and is not intended to constitute a security interest under Tribal Law.
        </Text>
        <Text bold italic>
          Error Resolution
        </Text>
        <Text>
          In case of errors or questions about an AutoPay or electronic payment, call us at <Phone number='877-836-1518' /> or write to us at Mobiloans Customer Support, MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351. We must hear from you no later than sixty (60) days after we sent the FIRST Periodic Statement on which the problem or error appeared.
        </Text>
        <List>
          <ListItem>Tell us your name and account number;</ListItem>
          <ListItem>Describe the error or the transfer you are unsure about, and explain as clearly as you can why you believe it is an error or why you need more information; and</ListItem>
          <ListItem>Tell us the dollar amount of the suspected error.</ListItem>
        </List>
        <Text>
          If you tell us orally, we may require that you send us your complaint or question in writing within ten (10) <Text underline>Business Days</Text>.
        </Text>
        <Text>
          We will determine whether an error occurred within ten (10) <Text underline>Business Days</Text> after we hear from you and will correct any error promptly. If we need more time, however, we may take up to fourty-five (45) days to investigate your complaint or question. If we decide to do this, we will credit your account within ten (10) <Text underline>Business Days</Text> for the amount you think is in error, so that you will have the use of the money during the time it takes us to complete our investigation. If we ask you to put your complaint or question in writing and we do not receive it within ten (10) <Text underline>Business Days</Text>, we may not credit your account.
        </Text>
        <Text>
          For errors involving new Accounts (Accounts that have been open less than forty-five (45) days), we may take up to ninety (90) days to investigate your complaint or question. For new Accounts, we may take up to twenty (20) <Text underline>Business Days</Text> to credit your Account for the amount you think is in error.
        </Text>
        <Text>
          You will be liable for any unauthorized Electronic Funds Transfer (EFT) that occurs more than sixty (60) days after transmittal of the first periodic statement showing the unauthorized EFT and before you provide written notice, provided that we establish that the unauthorized EFT would not have occurred had you notified us within the sixty (60) day period. If we are unable to prove that the unauthorized EFT would not have occurred with proper notification, you will not be liable for the disputed amount.
        </Text>
        <Text>
          We will tell you the results within three (3) <Text underline>Business Days</Text> after completing our investigation. If we decide that there was no error, we will send you a written explanation. You may ask for copies of the documents that we used in our investigation. If you need more information about our error-resolution procedures, call us at <Phone number='877-836-1518' /> or write to us at Mobiloans Customer Support, MobiLoans, LLC, P.O. Box 1409, Marksville, LA 71351. If provisional credit was provided, the amount will be debited from your account. We will notify you of the date and the amount of the debit.
        </Text>
        <Text bold>
          You acknowledge that you are not required to consent to receive funds or repay amounts owing on your Mobiloans Credit Account by ACH debits and credits to your Demand Deposit Account. If you authorize us to effect ACH debit entries to your Demand Deposit Account for your Account, you also agree that this Electronic Payment Authorization is to remain in full force and effect unless you terminate such authority. If you terminate such authority, you agree to provide us with another means of payment acceptable to us in our sole discretion.
        </Text>
        <H2>
          XXII. Credit Bureau
        </H2>
        <Text>
          As part of the credit review process, we may obtain information about your credit history and standing from one or more consumer reporting agencies and/or other data providers. Once you have an account with us, we may also review your credit information for account monitoring purposes as permitted by the Fair Credit Reporting Act.
        </Text>
        <Text>
          We are currently reporting loan transaction history to one or more major credit reporting agencies. We will report payment history from your account on a monthly basis. Because we report on a monthly basis, please allow up to thirty (30) days for a recent transaction to appear on your credit report. If you have a question, complaint, or dispute regarding information reported to a credit bureau, contact us at <Email address='disputes@mobiloans.com' />, or call us at <Phone number='877-836-1518' />, or write to us at Mobiloans Customer Support, MobiLoans, LLC, P.O. Box 1409 Marksville, LA 71351. Please provide, in writing, the specific information being disputed, a basis for the dispute, and include all documents that support the dispute. We will conduct an investigation with regards to the information provided within thirty (30) days of receipt. If it is determined that an error was made, we will immediately notify each consumer reporting agency we report to of the error and provide them with the correct information.
        </Text>
      </Flex>
    </Printable>
  )
}
