
import './styles/main.scss'

import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { Layout, Provider } from './containers'

import AboutUs from './screens/AboutUs'
import ApplyNow from './screens/ApplyNow'
import ConsumerCost from './screens/ConsumerCost'
import ContactUs from './screens/ContactUs'
import Disclosures from './screens/Disclosures'
import FAQ from './screens/FAQ'
import FinancialTips from './screens/FinancialTips'
import Home from './screens/Home'
import HowItWorks from './screens/HowItWorks'
import LendingPolicy from './screens/LendingPolicy'
import Login from './screens/Login'
import PrivacyPolicy from './screens/PrivacyPolicy'
import Rewards from './screens/Rewards'
import Security from './screens/Security'
import TermsConditions from './screens/TermsConditions'
import Trustpilot from './screens/Trustpilot'
import WhatItCosts from './screens/WhatItCosts'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Provider>
      <Router>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='about-us' element={<AboutUs />} />
            <Route path='consumer-cost' element={<ConsumerCost />} />
            <Route path='contact-us' element={<ContactUs />} />
            <Route path='disclosure' element={<Disclosures />} />
            <Route path='faq' element={<FAQ />} />
            <Route path='financial-tips' element={<FinancialTips />} />
            <Route path='apply-now' element={<ApplyNow />} />
            <Route path='how-it-works' element={<HowItWorks />} />
            <Route path='login' element={<Login />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path='responsible-lending-policy' element={<LendingPolicy />} />
            <Route path='rewards' element={<Rewards />} />
            <Route path='security-center' element={<Security />} />
            <Route path='what-it-costs' element={<WhatItCosts />} />
            <Route path='terms-and-conditions' element={<TermsConditions />} />
            <Route path='trustpilot' element={<Trustpilot />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
