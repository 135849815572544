
import {
  Flex,
  Image,
  Text,
} from 'react-mobi'

import { Default } from '../layouts'
import { Title } from '../components'

export default function AboutUs() {
  const heading = (<Title>About Us</Title>)

  return (
    <Default heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Flex full>
          <Image src='/images/tunica_logo_Desktop.png' />
        </Flex>
        <Flex gap='1rem' start>
          <Text>
            MobiLoans, LLC, is owned and operated by the Tunica-Biloxi Tribe of Louisiana, a federally recognized Indian tribe operating within the tribe's reservation. Our tribe was federally recognized in 1981 and has roughly 1,822 members.
          </Text>
          <Text>
            The Tunica-Biloxi People have lived on their reservation near Marksville, LA for over two centuries. At one point in time, the Tunica-Biloxi exercised influence over a wide territory, encompassing present-day Arkansas, Oklahoma, Missouri, Tennessee, Louisiana, Alabama, and Florida. The full motto on the Tunica-Biloxi flag, "Cherishing Our Past, Building for Our Future," both summarizes four-and-a-half centuries of tribal history and highlights the tribe's lasting contributions to a keystone Native American belief: the reverence and preservation of ancestral traditions.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='2rem'>
            Our Leadership
          </Text>
          <Text>
            Chairman of the Board, Marshall Pierite is responsible for the overall management of Mobiloans, the tribe's first online lending venture. After serving in the United States Marine Corps, the Tunica-Biloxi Tribe hired Pierite in 1987 as the Contract Medical Clerk until 1993, when he was promoted to Social Services Director on the reservation. In May of 1993, Pierite was elected to the Tunica-Biloxi Tribal Council. For more than 30 years, he has held several elected positions on the Tribal Council including Secretary-Treasurer, Vice-Chairman and Chairman of the Tribe. He is currently serving his second term as Chairman of the Tunica-Biloxi Tribe. In 2022, Pierite was selected as Tribal Leader of the Year by the Native American Finance Officers Association (NAFOA).
          </Text>
          <Text>
            The Corporation desires to create advanced employment opportunities for Tribal members and the non-tribal members of the larger communities where Tribal businesses are located. Mobiloans adds to the financial success and diversity of the Tribe.
          </Text>
        </Flex>
      </Flex>
    </Default>
  )
}
