
import {
  Carousel,
  CarouselItem,
  Flex,
  H1,
  Image,
  Link,
  Text,
} from 'react-mobi'

export function Reviews() {
  const image = (<Image src='/images/trustpilot.png' width='20rem' />)

  return (
    <Flex style={{maxWidth: '90vw'}}>
      <Carousel>
        <CarouselItem>
          <Flex margin='0 2rem'>
            {image}
            <H1>Great customer service</H1>
            <Text italic size='1.5rem'>
              The application process was easy and fast. I needed money for a vacation trip, and it came right on time! Thanks for the great customer service and friendly representatives.
            </Text>
          </Flex>
        </CarouselItem>
        <CarouselItem>
          <Flex margin='0 2rem'>
            {image}
            <H1>Wow!</H1>
            <Text italic size='1.5rem'>
              I was in need of a a little financial help and Mobiloans was there when I needed them. Mobiloans gives quick, personal service with several payback options.<br />
              Thank you<br />
              Monica
            </Text>
          </Flex>
        </CarouselItem>
        <CarouselItem>
          <Flex margin='0 2rem'>
            {image}
            <H1>Best ppl ever</H1>
            <Text italic size='1.5rem'>
              They were fast and awesome in helping me with my needs and the minimum payments are great to where I can afford to pay ty so much I would recommend this place for ur needs
            </Text>
          </Flex>
        </CarouselItem>
        <CarouselItem>
          <Flex margin='0 2rem'>
            {image}
            <H1>Friendly</H1>
            <Text italic size='1.5rem'>
              Friendly, fast , easy to apply , thank you for my approval Mobiloans
            </Text>
          </Flex>
        </CarouselItem>
      </Carousel>
      <div style={{height: '3rem'}} />
      <Link button href='/trustpilot'>See What People are Saying</Link>
    </Flex>
  )
}
