
import { useContext } from 'react'

import {
  Background,
  Card,
  Divider,
  Flex,
  Formatter,
  Highlight,
  Link,
  Table,
  Text,
} from 'react-mobi'

import { Calculator, Subtitle, Title, Website } from '../components'
import { Default } from '../layouts'
import { ProviderContext } from '../commons'

export default function WhatItCosts() {
  const {apr, charges, fees, isLoggedIn} = useContext(ProviderContext)

  const heading = (
    <>
      <Title>
        What are the costs and fees of a Mobiloans line of credit?
      </Title>
      <Subtitle>
        Please scroll down to read important information about rates, fees, and other cost information before submitting your application.
      </Subtitle>
    </>
  )

  const min = apr.breakdowns[0]
  const max = apr.breakdowns[apr.breakdowns.length-1]

  return (
    <Default heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem'>
        <div className='card-responsive'>
          <Card padding='1rem'>
              <table className='plain'>
                <thead>
                  <tr>
                    <th>
                      <Text bold>Line of Credit Amount</Text><br />
                      (With full amount drawn)
                    </th>
                    <th>
                      <Text bold>Number of Billing Cycles</Text><br />
                      (1 Billing Cycle = approximately 14 days or 26 per year)
                    </th>
                    <th>
                      <Text bold>Total Fees</Text><br />
                      (Cash Advance and Fixed Finance Fees)
                    </th>
                    <th>
                      <Text bold>APR%</Text><br />
                      (No rewards discount)
                    </th>
                    <th>
                      <Text bold>Reward Level - Diamond APR%</Text><br />
                      (65% reduction in Cash Advance Fees and Fixed Finance Charges)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Formatter.currency(min.amount)}</td>
                    <td>{min.cycles}</td>
                    <td>{Formatter.currency(min.fees)}</td>
                    <td>{Formatter.percentage(min.apr)}</td>
                    <td>{Formatter.percentage(min.rewards)}</td>
                  </tr>
                  <tr>
                    <td>{Formatter.currency(max.amount)}</td>
                    <td>{max.cycles}</td>
                    <td>{Formatter.currency(max.fees)}</td>
                    <td>{Formatter.percentage(max.apr)}</td>
                    <td>{Formatter.percentage(max.rewards)}</td>
                  </tr>
                </tbody>
              </table>
          </Card>
          <Flex margin='1rem 0 0 0'>
            <Text center>
              Go to <Website route='/rewards' /> for more information on how to qualify.
            </Text>
          </Flex>
        </div>
        <Text center size='2rem'>
          APR ranges from <Highlight>{Formatter.percentage(apr.min)} - {Formatter.percentage(apr.max)}</Highlight>
        </Text>
        {isLoggedIn && <Calculator />}
        <Divider width='75%' />
        <Text bold center primary size='3rem' width='75%'>
          With Mobiloans, you are only charged 2 types of fees:
        </Text>
        <Flex center gap='1rem' width='75%'>
          <Text color='#ffffff' padding='1rem 2rem' size='2rem' style={{backgroundColor: '#400068', borderRadius: '50%'}}>
            1
          </Text>
          <Text bold center primary size='3rem'>
            Cash Advance Fee
          </Text>
          <Text center size='1.5rem'>
            A Cash Advance Fee is charged each time you take out cash. It is calculated as follows:
          </Text>
          <Flex width='50%'>
            <Table
              card
              cols={[
                {label: 'Draw Amount', name: 'amount'},
                {label: 'Cash Advance Fee', name: 'fee'},
              ]}
              rows={fees.map(({max, min, rate, ...item}) => {
                let amount = ''
                if (max && min) {
                  amount = `Over ${Formatter.currency(min)} up to ${Formatter.currency(max)}`
                } else if (max) {
                  amount = `Up to ${Formatter.currency(max)}`
                } else if (min) {
                  amount = `Over ${Formatter.currency(min)}`
                }

                const fee = `${Formatter.currency(rate)} for each ${Formatter.currency(item.amount)} of cash advanced`
                return {amount, fee}
              })} />
          </Flex>
          <Text>
            If you pay off your line of credit, including the Cash Advance Fee, during the first billing cycle (a billing cycle is approximately 2 weeks) you will only be charged the Cash Advance Fee.
          </Text>
        </Flex>
        <Flex center gap='1rem' width='75%'>
          <Text color='#ffffff' padding='1rem 2rem' size='2rem' style={{backgroundColor: '#400068', borderRadius: '50%'}}>
            2
          </Text>
          <Text bold center primary size='3rem'>
            Fixed Finance Charge
          </Text>
          <Text center size='1.5rem'>
            If you have an outstanding balance that lasts beyond one billing period (which typically lasts two weeks), a Fixed Finance Charge
            will be added to your account each billing cycle.<br />
            <br />
            The fixed finance charge is calculated as follows:
          </Text>
          <Flex width='50%'>
            <Table
              card
              cols={[
                {label: 'If the principal balance as of the last day of your prior Billing Cycle, less the amount of new cash advances made during billing cycle, was:', name: 'amount'},
                {label: 'Your Fixed Finance Charge will be:', name: 'charge'},
              ]}
              rows={charges.map(({max, min, ...item}) => {
                return {
                  amount: `${Formatter.currency(min)} - ${Formatter.currency(min)}`,
                  charge: item.amount > 0 ? Formatter.currency(item.amount) : 'No charge',
                }
              })} />
          </Flex>
        </Flex>
      </Flex>
      <Background tertiary>
        <Flex evenly gap='2rem' margin='3rem 5rem'>
          <Text center bold primary size='3rem'>
            Minimum payment warning
          </Text>
          <Text center size='1.25rem'>
            If you just make the minimum payment for each billing cycle, you will have to pay more in finance charges and it'll take a longer time for you to pay off your debt. For instance, if you owe one thousand dollars ($1,000.00) on a line of credit and if you make no additional cash draws on the line of credit and you only make the minimum payments, it could take up to fourteen (14) months to pay the balance for an estimated total payments of three thousand dollars ($3,000.00).
          </Text>
          <Text center size='1.25rem'>
            Once you get a Mobiloans line of credit, you'll receive statements regularly that clearly describe all fees and charges<Text super>1</Text> paid and when the next payment is required.
          </Text>
          <Text center size='1.25rem'>
            Check our <Link href='/terms-and-conditions'>Terms and Conditions</Link> for further details.
          </Text>
        </Flex>
      </Background>
    </Default>
  )
}
