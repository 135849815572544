
import {
  Divider,
  Flex,
  Footer as MobiFooter,
  Image,
  Link,
  Text,
} from 'react-mobi'

import { Spacer } from '../components'

export function Footer() {
  return (
    <MobiFooter>
      <Flex margin='-1rem 0 0 0'>
        <Flex full margin='5rem 0 3rem 0' width='90%'>
          <Divider dots={false} dashed />
          <Flex autoWrap evenly full gap='2rem' padding='2rem 0' row stretch>
            <Flex full start>
              <Text bold padding={0} upper>Company</Text>
              <Link href='/faq'>FAQ</Link>
              <Link href='/contact-us'>Contact Us</Link>
              <Link href='/about-us'>About Us</Link>
            </Flex>
            <Flex full start>
              <Text bold padding={0} upper>Legal</Text>
              <Link href='/terms-and-conditions'>Terms & Conditions</Link>
              <Link href='/privacy-policy'>Privacy Policy</Link>
              <Link href='/security-center'>Security</Link>
              <Link href='/disclosure'>Disclosures</Link>
              <Link href='https://www.tunicabiloxi.org/wp-content/uploads/Tunica-Biloxi-Tribe-of-Louisiana-Arbitration-Code-.pdf' open>Tunica-Biloxi Arbitration Code</Link>
              <Link href='https://www.tunicabiloxi.org/wp-content/uploads/Tunica-Biloxi-Fairness-in-Lending-Code.pdf' open>Tunica-Biloxi Fairness in Lending Code</Link>
            </Flex>
            <Flex full start>
              <Text bold padding={0} upper>Education</Text>
              <Link href='/consumer-cost'>Consumer Cost</Link>
              <Link href='/financial-tips'>Financial Tips</Link>
              <Link href='/responsible-lending-policy'>Responsible Lending Policy</Link>
            </Flex>
          </Flex>
          <Divider dashed dots={false} />
          <Spacer />
          <Text color='rgb(33, 37, 41)' legal>
            MobiLoans, LLC, a tribal lending entity wholly owned by the Tunica-Biloxi Tribe of Louisiana ("Tribe"), a federally recognized Indian tribe, operates within the Tribe's reservation. Certain limitations apply. See Terms and Conditions for complete details. All trademarks, service marks, and registered marks are the properties of their respective owners. Maximum line amount is $2,500.00. Refer to the Terms & Conditions for additional details. Mobiloans is an expensive form of short term credit and should not be used as a long term solution.<br />
            <br />
            <Text bold super>1</Text><Text underline><Link href="/terms-and-conditions">See Important Truth in Lending Disclosures.</Link></Text><br />
            <br />
            Additional fees may apply for checks returned due to insufficient funds.<br />
            <br />
            <Text bold super>2</Text>In most cases, requests for cash that are received and can be verified prior to 4:00 p.m. Central Time (Monday - Friday) may be funded as soon as the next business banking day (excluding weekends and bank holidays). Requests for cash received after 4:00 p.m. Central Time (Monday - Friday) may require an additional business banking day to fund.<br />
            <br />
            We adhere to the principles of the federal Fair Debt Collection Practices Act (FDCPA). Because we may report your payment history to one or more credit bureaus, late or non-payment of your loan may negatively impact your credit rating. If you fail to repay your loan in accordance with its terms, we may place your loan with or sell your loan to a third-party collection agency or other company that acquires and/or collects delinquent consumer debt.
          </Text>
        </Flex>
        <Flex margin='2rem'>
          <Link href='/responsible-lending-policy'>
            <Image src='/images/PRL.png' height='2.5rem' />
          </Link>
        </Flex>
      </Flex>
    </MobiFooter>
  )
}
