
import { useContext } from 'react'

import {
  Flex,
  Formatter,
  Image,
  Link,
  List,
  ListItem,
  Text,
} from 'react-mobi'

import { Default } from '../layouts'
import { ProviderContext } from '../commons'
import { Title } from '../components'

export default function ConsumerCost() {
  const {fees} = useContext(ProviderContext)

  const heading = (
    <Title>Consumer Cost</Title>
  )

  const lines = fees.map(({max, min, rate, ...item}) => {
    let line = 'For amounts drawn from your Mobiloans Credit '

    if (max && min) {
      line += `over ${Formatter.currency(min)} up to ${Formatter.currency(max)}`
    } else if (max) {
      line += `that are up to ${Formatter.currency(max)}`
    } else if (min) {
      line += `over ${Formatter.currency(min)}`
    }

    line += `, the Cash Advance Fee will be ${Formatter.currency(rate)} per ${Formatter.currency(item.amount)} drawn.`
    return line
  })

  return (
    <Default heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Flex gap='1rem' start>
          <Text bold size='1.25rem'>
            There's no easy solution, but here are a few simple tips to help you identify if Mobiloans is the answer for you.
          </Text>
          <Text>
            Crunch the numbers before you take out a Mobiloans line of credit. Make sure you consider the following:
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            How much does Mobiloans Cost?
          </Text>
          <Text>
            When you get a line of credit, you pay a Cash Advance Fee. {lines.join('  ')}
          </Text>
          <Text>
            If you pay your line of credit back in full, including the Cash Advance Fee, on or before your due date, there are no additional fees or charges.<Text super>1</Text>
          </Text>
          <Text>
            You can make payments on your Mobiloans line of credit at any time at Mobiloans.com.
          </Text>
          <Text>
            If you need more time to repay your Mobiloans Credit, a minimum charge is calculated on a tiered level based upon the unpaid balance. This fee is a Fixed Finance Charge, and will be charged to your account each billing cycle (<Text italic>approximately 2 weeks</Text>) you have an unpaid balance. <Text italic>There are a minimum of two billing cycles per month.</Text> Noted below are the outstanding principal balances with the appropriate Fixed Finance Charges.<Text super>1</Text>
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            How Does This Compare with the Other Options Out There?
          </Text>
          <Flex full>
            <Image src='/images/how-it-works-graph_tab.png' />
          </Flex>
          <Text>
            <Text super>1</Text>Bank overdraft fees are usually more expensive than a Mobiloans line of credit advance. For example, if you need to cover a single $40 purchase, bank overdraft protection would cost an average of $35, but a Mobiloans line of credit would cost only $6, if paid in full on the first billing cycle.
          </Text>
          <Text italic>
            Source: CFPB Proposes Rule to Protect Consumers from Unaffordable Overdraft Fees, Continuing Crackdow on Junk Fees (January 17, 2024); Retrieved from: https://www.responsiblelending.org/media/cfpb-proposes-rule-protect-consumers-unaffordable-overdraft-fees-continuing-crackdown-junk. Accessed March 25 2024.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            Is Mobiloans Right for You?
          </Text>
          <Text>
            Remember, a Mobiloans line of credit is for short-term cash needs and isn't the right solution for every financial situation. Before you apply, always consider these lower cost options:
          </Text>
          <List>
            <ListItem>Borrow money from a friend, family member, or your employer.</ListItem>
            <ListItem>Request a payment extension from the company that sent you a bill.</ListItem>
            <ListItem>Check with your bank or credit card provider to see if they can provide a lower cost credit option.</ListItem>
          </List>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            What Other Financial Information is Out There?
          </Text>
          <Text>
            Click on the buttons below to visit some helpful financial sites:
          </Text>
          <List>
            <ListItem>
              <Link href='http://www.ftc.gov'>Federal Trade Commision</Link>
            </ListItem>
            <ListItem>
              <Link href='https://www.mymoney.gov'>MyMoney.gov</Link>
            </ListItem>
            <ListItem>
              <Link href='http://www.federalreserve.gov'>Federal Reserve System</Link>
            </ListItem>
            <ListItem>
              <Link href='http://www.annualcreditreport.com'>Annual Credit Report</Link>
            </ListItem>
          </List>
        </Flex>
      </Flex>
    </Default>
  )
}
