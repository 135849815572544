
import { useEffect, useState } from 'react'

import { Background, Flex } from 'react-mobi'

import { Notification } from '../components'
import { Service } from '../commons'

export interface NotificationsProps {
}

export function Notifications({}: NotificationsProps) {
  const [message, setMessage] = useState<string | undefined>()

  async function load() {
    setMessage(await Service.siteToast())
  }

  function handleClose() {
    setMessage(undefined)
  }

  useEffect(() => {
    load()
  }, [])

  if (!message) return null

  return (
    <div className='notifications'>
      <Flex>
        <Background style={{width: '100%'}} tertiary>
          <Flex gap='1rem' padding='0.5rem 1rem'>
            <Notification callback={handleClose} message={message as string} />
          </Flex>
        </Background>
      </Flex>
    </div>
  )
}
