
import {
  Animation,
  Background,
  Card,
  Divider,
  Flex,
  Hero,
  Image,
  Text,
} from 'react-mobi'

import { Landing } from '../layouts'

export default function HowItWorks() {
  const heading = (
    <Flex height='40vh' style={{minHeight: '50rem', zIndex: 2}}>
      <Hero image='/images/get-the-cash-you-need-mobiloans.jpg' bottom>
        <Flex evenly style={{minHeight: '25rem'}}>
          <Text bold size='3rem'>
            How it works
          </Text>
          <Text center size='1.5rem' width='75%'>
            Getting started is quick and simple! Find out if you qualify in usually 5 minutes or less with 3 easy steps.
          </Text>
        </Flex>
      </Hero>
    </Flex>
  )

  return (
    <Landing heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem 0 3rem'>
        <Card>
          <Flex stretch autoWrap full row>
            <Background tertiary>
              <Flex>
                <Text bold margin={0} padding={0} primary size='3rem'>Step 1</Text>
                <Text margin={0} padding={0} primary>Submit your application</Text>
                <Flex padding='1rem'>
                  <Image src='/images/credit_check_icon.png' />
                </Flex>
              </Flex>
            </Background>
            <Flex full padding='2rem' style={{flex: '2'}}>
              <Text size='1.5rem'>
                Fill out our quick and secure 3-minute online form with your driver's license, pay stub, personal check, and social security card handy.
              </Text>
            </Flex>
          </Flex>
        </Card>
        <Card>
          <Flex stretch autoWrap full row>
            <Background tertiary>
              <Flex>
                <Text bold margin={0} padding={0} primary size='3rem'>Step 2</Text>
                <Text margin={0} padding={0} primary>Find out your credit limit</Text>
                <Flex padding='1rem'>
                  <Image src='/images/credit_limit_icon.png' />
                </Flex>
              </Flex>
            </Background>
            <Flex full padding='2rem' style={{flex: '2'}}>
              <Text size='1.5rem'>
                Just a few seconds after you submit your application, you can find out the maximum amount of cash you can receive.
              </Text>
            </Flex>
          </Flex>
        </Card>
        <Card>
          <Flex stretch autoWrap full row>
            <Background tertiary>
              <Flex>
                <Text bold margin={0} padding={0} primary size='3rem'>Step 3</Text>
                <Text margin={0} padding={0} primary>Get the cash you need</Text>
                <Flex padding='1rem'>
                  <Image src='/images/loan_made_icon.png' />
                </Flex>
              </Flex>
            </Background>
            <Flex full padding='2rem' style={{flex: '2'}}>
              <Text size='1.5rem'>
                Once approved and verified, you will be provided with the funds up to your approved limit! The money may be transferred into your checking account as soon as the next business day<Text super>2</Text>. The credit that you seek without having to worry about a card.
              </Text>
            </Flex>
          </Flex>
        </Card>
        <Flex autoWrap row width='75%'>
          <Image src='/images/mobile_hand_icon.png' />
          <Text bold center size='3rem'>
            Credit access without a credit card
          </Text>
        </Flex>
      </Flex>
      <Background primary>
        <Animation fadeIn threshold={0.5}>
          <Flex>
            <Text center margin='5rem' size='1.5rem' tertiary>
              Mobiloans offers you a line of credit; the money can be directly deposited into your checking account, or if you wish, you can choose to receive it in check form. The amount that you can take out is completely up to you - and as you make payments on your balance, more funds become available for withdrawal.
            </Text>
          </Flex>
        </Animation>
      </Background>
      <Image cover height='30rem' src='/images/flexibility-that-fits-you-mobiloans.jpg' width='100%' />
      <Background tertiary>
        <Animation fadeIn threshold={0.5}>
          <Flex evenly padding='2rem'>
            <Text bold center primary size='3rem'>
              Your credit limit
            </Text>
            <Text center padding='2rem 0' size='1.5rem' width='75%'>
              Customers are able to receive loan amounts ranging from <Text bold>$500 to $2,500</Text>. If you demonstrate consistent payment history<Text super>*</Text>, you may become eligible for an increase in your credit line (up to a maximum of $2,500).
            </Text>
            <Text center padding='2rem 0' width='75%'>
              <Text super>*</Text>A “successful payment” is a payment that is made on time, for the full amount, and is not returned.
            </Text>
          </Flex>
        </Animation>
      </Background>
      <Flex evenly gap='5rem' margin='5rem 0'>
        <Animation slideLeft threshold={0.01}>
          <Flex>
            <Text bold center primary size='3rem'>
              Simple flat fee
            </Text>
            <Text center padding='2rem 0' size='1.5rem' width='75%'>
              Mobiloans charges a flat fee for each $20 you draw, rather than daily interest like credit cards. The great thing is that when you pay back the total amount within the first billing cycle, no other fees or expenses will be added!!<Text super>1</Text>
            </Text>
          </Flex>
        </Animation>
        <Divider width='75%' />
        <Animation slideRight threshold={0.01}>
          <Flex>
            <Text bold center primary size='3rem'>
              Flexibility that fits you
            </Text>
            <Text center padding='2rem 0' size='1.5rem' width='75%'>
              If you require extra time to pay off your Mobiloans Credit, a Fixed Finance Charge<Text super>1</Text> will be added to each of your billing cycles while there is an unpaid balance in your account.
            </Text>
          </Flex>
        </Animation>
        <Divider width='75%' />
        <Animation slideLeft threshold={0.01}>
          <Flex>
            <Text bold center primary size='3rem'>
              Effortless, automatic payments
            </Text>
            <Text center padding='2rem 0' size='1.5rem' width='75%'>
              Forget about bills when you use AutoPay with your checking account. Your payments will be deducted from your bank account automatically on the due date. Alternatively, mail in your payment via check or money order.
            </Text>
          </Flex>
        </Animation>
        <Divider width='75%' />
        <Animation slideRight threshold={0.01}>
          <Flex>
            <Text bold center primary size='3rem'>
              Payment plan
            </Text>
            <Text center padding='2rem 0' size='1.5rem' width='75%'>
              Your initial payment is due within a month after you request your line of credit. All subsequent payments will be made every two weeks, depending on how often you get paid. <Text italic>You are obligated to make at least two minimum payments each month.</Text> With Mobiloans, there are no prepayment penalties, so you can pay off your line of credit whenever you choose and only be charged fees<Text super>1</Text> for the time you have an outstanding balance.
            </Text>
          </Flex>
        </Animation>
      </Flex>
    </Landing>
  )
}
