
import {
  Flex,
  Link,
  Text,
} from 'react-mobi'

import { Default } from '../layouts'
import { Title } from '../components'

export default function FinancialTips() {
  const heading = (
    <Title>Financial Tips</Title>
  )

  return (
    <Default heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem' start>
        <Flex gap='1rem' start>
          <Text bold size='2rem'>
            10 tips to "snap" out of debt
          </Text>
          <Text>
            Do your bills always seem to be bigger than your paycheck? You're not alone. Millions of Americans - even those who earn big paychecks - struggle with debt. There's no easy solution, but here are a few simple tips to help you stretch your paycheck:
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            1. Face it and take charge.
          </Text>
          <Text>
            Taking charge of your debt means making lists. Make a list of what you owe this month and compare it to your paycheck. When the numbers are on paper, they're actually easier to face.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            2. Look your paycheck in the eye.
          </Text>
          <Text>
            Think you're making $30,000 a year? $40,000 a year? More than you've ever made before? Congratulations - but don't forget to think about “net” income and not “gross” income. Net income is what you have left after taxes, insurance, and other expenses that come out of your paycheck before you get it. If your budget isn't based on net income, you might be adding debt without even knowing it.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            3. Add up what's going out the door.
          </Text>
          <Text>
            List time again. Only this one isn't what you owe — it's what you spend. Lunches, lattes, having your nails done. Keep a log of expenses for a week, then be prepared. You'll be shocked at how fast those little expenses add up!
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            4. Make the cut at home.
          </Text>
          <Text>
            Bet you didn't know there's hidden money in every room in your house. Take the kitchen, for example. You can save big by joining a food club. They can be much cheaper than grocery stores. That's significant, since the average family of four spends $1,300 a month on food.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            5. Make the cut at work.
          </Text>
          <Text>
            Don't think nickels and dimes add up? They do at work. Pack just two lunches a week, and cut back on lattes, and you could save over $1000 a year — enough to take a bite out of your credit card debt.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            6. Pay on time - and stop paying for time!
          </Text>
          <Text>
            Want to beat credit cards at their own game? Be sure to pay on time so you can avoid expensive late fees, and always pay more than the minimum. Call in to get your interest rates lowered — nearly half of all customers who complain have been able to do just that. And finally, think green! If you use cash instead of credit cards, you'll find you automatically spend a lot less.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            7. Give your checking account a checkup.
          </Text>
          <Text>
            Bank overdraft protection fees can catch you by surprise - and they add up quickly. So keep an eye on your account balance and whatever you do, don't overdraw. Overdrafts can be one of the biggest causes of unexpected expense.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            8. Say goodbye to the material girl.
          </Text>
          <Text>
            If you're bored or tired, stay out of the mall. That's when the experts say you're most likely to get into buying trouble. Even so, if you still find yourself spending too much on daily or personal expenses, make a list of your purchases. Wait a few days, then go back and put these purchases in three separate categories: “Want,” “Need” and “Can Do Without.” Need we say more?
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            9. Polish your credit rating.
          </Text>
          <Text>
            A good credit rating enables you to get better terms on all of the money you borrow. So be sure to find out where you stand with the three major credit reporting agencies. Dispute any discrepancies. Explain anything that makes you look like a bad risk. The bureaus may not remove disputed items, but they will include an explanation in your file. Go to <Link href='http://www.annualcreditreport.com/'>www.annualcreditreport.com</Link> to get a free credit report.
          </Text>
        </Flex>
        <Flex gap='1rem' start>
          <Text bold size='1.5rem'>
            10. Count down to zero - create your own personal payment plan.
          </Text>
          <Text>
            Enough advice - it's crunch time. Time to pull together all your lists so you can figure out how long it will take to get back to zero. You start with what's coming in. Then you look at what's going out. You calculate your regular monthly expenses and your on-going debt like credit cards. Then you decide exactly how much you can pay and how long it will take. Once all the numbers are committed to a paper or a computer screen, you lay out your personal payment plan. It's a month-by-month map that gives you the supreme satisfaction of crossing off your debt.
          </Text>
        </Flex>
      </Flex>
    </Default>
  )
}
