
import {
  Button,
  Divider,
  Flex,
  H1,
  Hero,
  Image,
  Link,
  Text,
} from 'react-mobi'

import { Counter, Reviews } from '../components'
import { Landing } from '../layouts'
import { Notifications } from '../containers'

export default function Home() {
  const heading = (
    <>
      <Notifications />
      <Flex height='40vh' style={{minHeight: '50rem', zIndex: 2}}>
        <Hero image='/images/cash-when-you-need-it-mobiloans.jpg' left>
          <Flex start>
            <H1>
              Since 2011, we've been a reliable and trusted lender, providing over <Text primary><Counter amount={Math.round((new Date()).getTime() / 1000)} /></Text> in emergency cash to assist over 500,000 customers.
            </H1>
            <Text>
              Mobiloans - we have the answer to your urgent cash needs, both now and in the future. Our line of credit offers you flexibility when it is most crucial. Get going now, and apply for up to $2,500 by tomorrow.<Text super>2</Text>
            </Text>
            <Link button href='https://mobiloans.com/apply'>Apply Now</Link>
          </Flex>
        </Hero>
      </Flex>
    </>
  )

  function handleApplyNow() {
    window.location.href = 'https://mobiloans.com/apply'
  }

  return (
    <Landing heading={heading}>
      <Image cover height='45rem' src='/images/urgent-cash-needs-mobiloans.jpg' width='100%' />
      <Flex full margin='-15rem 0 0 0' style={{backgroundColor: '#ffffff'}}>
        <Flex gap='5rem' padding='5rem 3rem'>
          <Reviews />
          <Divider width='75%' />
          <Flex autoWrap center gap='3rem' row stretch>
            <Flex between full gap='1rem'>
              <Image src='/images/broken_car.png' width='10rem' />
              <H1 center>A new type of emergency cash</H1>
              <Text center>
                A Mobiloans line of credit is the new, more flexible way to borrow emergency cash. Use it to avoid costly bank overdraft fees and payday loans.
              </Text>
              <Link button href='/what-it-costs'>See What it Costs</Link>
            </Flex>
            <Flex between full gap='1rem'>
              <Image src='/images/calendar.png' width='10rem' />
              <H1 center>Flexibility when you need it</H1>
              <Text center>
                With flexible payment options and no hidden fees or prepayment penalties, a Mobiloans line of credit gives you more ways to manage life's everyday expenses.<Text super>1</Text> <Link href='/what-it-costs'>Please see important disclosures</Link>.
              </Text>
              <Link button href='/how-it-works'>See How it Works</Link>
            </Flex>
            <Flex between full gap='1rem'>
              <Image src='/images/mobile_phone.png' width='10rem' />
              <H1 center>Credit without the card</H1>
              <Text center>
                Need a little extra cash to make ends meet until your next paycheck? We're here 24 hours a day. Apply now and see how much cash you can get.
              </Text>
              <Button callback={handleApplyNow} label='Apply Now' secondary />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex autoWrap between margin='5rem 0 0 0' row stretch>
        <Flex between center full gap='2rem' padding='3rem' style={{backgroundColor: 'rgb(246, 232, 255)'}}>
          <Image src='/images/award.png' margin='-3rem 0 0 0' width='12rem' />
          <H1 center>Rewards that matter</H1>
          <Text center width='70%'>
            Stay on top of payments and reap the rewards! Receive discounts of <Text bold primary>10% - 65% </Text> on future Cash Advance Fees and Fixed Finance Charges.<Text super>1</Text>
          </Text>
          <Link button href='/rewards'>Learn More</Link>
        </Flex>
        <Flex full>
          <Image cover height='30rem' src='/images/credit-without-the-card-mobiloans.jpg' width='100%' />
        </Flex>
      </Flex>
    </Landing>
  )
}
