
import {
  Divider,
  Flex,
  Hero,
  Image,
  Link,
  Text,
} from 'react-mobi'

import { Landing } from '../layouts'

interface Review {
  comment: string
  name: string
}

export default function Trustpilot() {
  const heading = (
    <Flex height='40vh' style={{minHeight: '50rem'}}>
      <Hero image='/images/urgent-cash-needs-mobiloans.jpg' bottom>
        <Flex evenly gap='1rem' margin='0 0 3rem 0'>
          <Text bold center primary size='3rem'>
            14,786 reviews and counting
          </Text>
          <Text center size='1.25rem' width='75%'>
            For over a decade, we've been dedicated to helping our customers with their cash needs! We know that unforeseen crises can happen more than once, so a Mobiloans line of credit is the ideal solution. With this kind of loan, you can access your available funds whenever you need them. Other lenders only offer one-off loans - nothing else. Hear what our satisfied customers have to say and find out how Mobiloans can help you too!
          </Text>
        </Flex>
      </Hero>
    </Flex>
  )

  const reviews: Array<Review> = [
    {
      comment: 'Really great company with professional and polite people!!! Saved my life for a few days due to traveling mix ups.',
      name: 'Lindy',
    },
    {
      comment: 'The process of applying for a loan was simple and easy to follow. The funds are deposited into your account quickly.',
      name: 'Kathleen',
    },
    {
      comment: 'Everything was as simple and stressfree as the ad explains. No difficult task or navigation through several different questions in order to complete the loan.',
      name: 'Sherry',
    },
    {
      comment: 'First of all I did not realize I would get the full $2500 and when I did, it so easy to get the small amount I needed right away and they kept in contact since, asking if everything went well and so on. Great job!! Thanks!',
      name: 'Edward',
    },
    {
      comment: 'The application process was easy and fast. I needed money for a vacation trip and in came right on time. Great customer service and friendly representative.',
      name: 'Renee',
    },
  ]

  return (
    <Landing heading={heading}>
      <Flex gap='5rem' margin='5rem 3rem'>
        <Image src='/images/tp_header.png' />
        <Link button href='https://www.trustpilot.com/review/mobiloans.com'>Visit Our Official Trustpilot Page</Link>
        {reviews.map((review, index) => (
          <Flex key={index} gap='1rem' width='75%'>
            <Divider margin='0 0 3rem 0' />
            <Image src='/images/trustpilot.png' width='20rem' />
            <Text center size='1.25rem'>
              {review.comment}
            </Text>
            <Text bold center size='2rem'>{review.name}</Text>
          </Flex>
        ))}
      </Flex>
    </Landing>
  )
}
